export const VALID_GUESSES = [
  'аанта',
  'анфас',
  'абаза',
  'абази',
  'абайя',
  'абака',
  'абвер',
  'абгаз',
  'абзац',
  'абзим',
  'абиоз',
  'аборт',
  'абрис',
  'абцуг',
  'абшид',
  'абшит',
  'абьюз',
  'аваль',
  'аванс',
  'авгит',
  'авеню',
  'аверс',
  'авизо',
  'авлос',
  'авост',
  'авось',
  'аврал',
  'авран',
  'аврат',
  'австр',
  'автик',
  'автол',
  'агава',
  'агапэ',
  'агент',
  'агогэ',
  'агора',
  'аграф',
  'агуша',
  'адатт',
  'адвил',
  'аддон',
  'адище',
  'адоба',
  'адрес',
  'адрон',
  'адряс',
  'аевит',
  'ажгон',
  'азарт',
  'азери',
  'айван',
  'айвар',
  'айдол',
  'аймак',
  'айоли',
  'айпад',
  'айпод',
  'айпэд',
  'айрак',
  'айран',
  'айрол',
  'айрон',
  'айсик',
  'айтыс',
  'айтыш',
  'айфон',
  'акажу',
  'акант',
  'аканф',
  'акаша',
  'акаэм',
  'акида',
  'аккум',
  'акмит',
  'акома',
  'акрил',
  'акрон',
  'аксис',
  'аксон',
  'актея',
  'актив',
  'актин',
  'акциз',
  'акция',
  'алаит',
  'алань',
  'аларм',
  'алгид',
  'алгия',
  'алгол',
  'алерт',
  'алиас',
  'алиби',
  'алкан',
  'алкен',
  'алкид',
  'алкил',
  'алкин',
  'аллен',
  'аллея',
  'аллил',
  'аллод',
  'аллюр',
  'алмаз',
  'алтан',
  'алтей',
  'алтея',
  'алтын',
  'алунд',
  'алуне',
  'алфер',
  'алчба',
  'алыча',
  'альба',
  'альни',
  'альфа',
  'амбар',
  'амбра',
  'амбре',
  'амвон',
  'амида',
  'аминь',
  'аммин',
  'ампер',
  'ампир',
  'амула',
  'анаша',
  'анбан',
  'анбар',
  'анбур',
  'ангар',
  'ангас',
  'ангоб',
  'ангус',
  'анзац',
  'анзур',
  'аниме',
  'анион',
  'анкап',
  'анкер',
  'анкор',
  'анлим',
  'аннит',
  'анона',
  'анонс',
  'анрег',
  'ансаб',
  'антал',
  'антей',
  'антем',
  'антик',
  'антре',
  'анчар',
  'аньюм',
  'аозай',
  'аорта',
  'апарт',
  'апекс',
  'аплит',
  'апноэ',
  'апорт',
  'апрош',
  'апрув',
  'апсия',
  'арака',
  'арбор',
  'арбуз',
  'аргал',
  'аргиш',
  'аргон',
  'ареал',
  'арека',
  'арена',
  'арест',
  'арзни',
  'аркад',
  'аркан',
  'аркоз',
  'армия',
  'армюр',
  'армяк',
  'арнис',
  'арсин',
  'арсис',
  'артос',
  'артус',
  'архат',
  'архей',
  'архив',
  'арчак',
  'аршин',
  'асана',
  'аскос',
  'аспид',
  'ассам',
  'астат',
  'астма',
  'астра',
  'асцит',
  'аська',
  'атака',
  'атилл',
  'атлас',
  'атман',
  'атолл',
  'атрий',
  'аттач',
  'аттик',
  'атуот',
  'аудио',
  'аудит',
  'аулос',
  'аурат',
  'аурей',
  'аурид',
  'аутро',
  'афган',
  'афера',
  'афиша',
  'ахилл',
  'ахоит',
  'ашель',
  'ашрам',
  'аэроб',
  'аэрон',
  'аюмаа',
  'бабах',
  'баббл',
  'бабка',
  'бабки',
  'бабло',
  'бабье',
  'багаж',
  'багги',
  'багер',
  'багет',
  'багно',
  'багня',
  'багор',
  'бадан',
  'бадик',
  'бадуй',
  'бадья',
  'базар',
  'базин',
  'базис',
  'базит',
  'баинг',
  'байга',
  'байге',
  'байда',
  'байза',
  'байка',
  'бакай',
  'бакан',
  'бакен',
  'бакун',
  'бакча',
  'бакша',
  'балан',
  'балда',
  'балдж',
  'балет',
  'бализ',
  'балка',
  'балок',
  'балык',
  'бамия',
  'банан',
  'банда',
  'бандл',
  'бандо',
  'бандх',
  'банка',
  'барак',
  'баран',
  'барда',
  'бардо',
  'бареж',
  'баржа',
  'барий',
  'барит',
  'барка',
  'бармы',
  'барок',
  'барре',
  'барыш',
  'басик',
  'баска',
  'басма',
  'баснь',
  'басня',
  'басок',
  'басон',
  'бастр',
  'батан',
  'батат',
  'батик',
  'батог',
  'батон',
  'баттл',
  'батуд',
  'батун',
  'батут',
  'бауит',
  'бафия',
  'бахта',
  'бахча',
  'бачка',
  'бачок',
  'башка',
  'башли',
  'башня',
  'баяти',
  'бебра',
  'бебут',
  'бегаш',
  'бегун',
  'бедка',
  'бедро',
  'безик',
  'бейгл',
  'бейдж',
  'бейка',
  'бейцы',
  'бекар',
  'бекет',
  'бекон',
  'белен',
  'белка',
  'белки',
  'белок',
  'белье',
  'беляш',
  'бемба',
  'бемит',
  'бемит',
  'бенди',
  'бенто',
  'берат',
  'бердо',
  'берег',
  'берек',
  'берет',
  'берка',
  'берма',
  'берсо',
  'берцо',
  'берцы',
  'бесик',
  'бетон',
  'бздеж',
  'бздех',
  'биакс',
  'бибоп',
  'бибут',
  'бивак',
  'бигос',
  'бидон',
  'бикса',
  'билет',
  'билин',
  'билль',
  'билон',
  'бинго',
  'бинди',
  'бином',
  'биота',
  'бипак',
  'бипер',
  'биржа',
  'бирка',
  'бисак',
  'бисер',
  'бистр',
  'битва',
  'битер',
  'битка',
  'биток',
  'битум',
  'битье',
  'бичик',
  'благо',
  'блажь',
  'бланк',
  'блант',
  'бланш',
  'бласт',
  'блато',
  'бленд',
  'блеск',
  'близь',
  'блинд',
  'блинт',
  'блиск',
  'блонд',
  'блоть',
  'блуза',
  'блюдо',
  'бляха',
  'бобок',
  'боген',
  'бодхи',
  'бодяк',
  'божба',
  'бозар',
  'бозон',
  'боинг',
  'бойка',
  'бойня',
  'бокаж',
  'бокал',
  'бокит',
  'болид',
  'болюс',
  'бомба',
  'бонго',
  'бонка',
  'бонки',
  'бонмо',
  'бонус',
  'боран',
  'борат',
  'бордо',
  'борей',
  'борец',
  'борид',
  'борий',
  'борок',
  'борть',
  'ботва',
  'ботик',
  'бофор',
  'бочаг',
  'бочка',
  'бочок',
  'бочче',
  'бошка',
  'брага',
  'брада',
  'брама',
  'брана',
  'бранч',
  'брань',
  'браса',
  'брасс',
  'бреве',
  'бреда',
  'бредь',
  'брезг',
  'брейк',
  'бремя',
  'бренд',
  'брешь',
  'брида',
  'бридж',
  'брика',
  'бриль',
  'брифы',
  'бровь',
  'броги',
  'бронх',
  'бронь',
  'броня',
  'брошь',
  'бртуч',
  'брыжи',
  'брыла',
  'брыль',
  'брэнд',
  'брюки',
  'брюхо',
  'бубен',
  'бубка',
  'бубна',
  'бубны',
  'бубон',
  'бугай',
  'бугор',
  'будка',
  'будра',
  'будяк',
  'буква',
  'букет',
  'букле',
  'букля',
  'букол',
  'букса',
  'булат',
  'булга',
  'булик',
  'булка',
  'булла',
  'бульб',
  'бульк',
  'бумер',
  'бурав',
  'бурак',
  'буран',
  'бурат',
  'бурда',
  'бурка',
  'бурре',
  'бурса',
  'бурун',
  'бутан',
  'бутен',
  'бутер',
  'бутик',
  'бутил',
  'бутит',
  'бутня',
  'бутон',
  'бутса',
  'буфер',
  'буфет',
  'бухач',
  'бухид',
  'бухло',
  'бухта',
  'бушма',
  'бушон',
  'буява',
  'быдло',
  'былие',
  'былка',
  'былое',
  'былье',
  'бытие',
  'бытье',
  'бычок',
  'бьюик',
  'бэкап',
  'бэкон',
  'бэшка',
  'бювар',
  'бювет',
  'бюкса',
  'вабик',
  'вавка',
  'вагон',
  'вадья',
  'вазик',
  'вазон',
  'вакат',
  'вакса',
  'вакуф',
  'валеж',
  'валек',
  'валер',
  'валец',
  'валик',
  'валка',
  'валки',
  'валок',
  'валуй',
  'валун',
  'вальс',
  'вамби',
  'ванна',
  'ванта',
  'вапор',
  'варга',
  'варез',
  'варик',
  'вария',
  'варка',
  'варна',
  'варок',
  'ватер',
  'ватин',
  'ватка',
  'ватты',
  'вафля',
  'вахта',
  'вброс',
  'вебер',
  'вебка',
  'ведет',
  'ведом',
  'ведро',
  'ведро',
  'вежда',
  'векша',
  'велик',
  'велми',
  'велум',
  'вельд',
  'велюр',
  'венец',
  'веник',
  'венка',
  'венок',
  'вента',
  'веняк',
  'верба',
  'вервь',
  'веред',
  'верес',
  'верея',
  'верже',
  'верки',
  'версо',
  'верфь',
  'верша',
  'вески',
  'весло',
  'весна',
  'весок',
  'весть',
  'ветвь',
  'ветер',
  'ветка',
  'ветла',
  'вечер',
  'вешка',
  'взбег',
  'взвар',
  'взвод',
  'взвоз',
  'вздор',
  'вздох',
  'взлет',
  'взлом',
  'взмах',
  'взмет',
  'взмыв',
  'взнос',
  'взрез',
  'взрыв',
  'взрыд',
  'взъеб',
  'взъем',
  'взыск',
  'видак',
  'видео',
  'видик',
  'видма',
  'видок',
  'видос',
  'виела',
  'визаж',
  'визир',
  'визит',
  'визор',
  'вилка',
  'вилла',
  'вилок',
  'винда',
  'винил',
  'винир',
  'винцо',
  'винча',
  'виола',
  'випка',
  'вираж',
  'вирга',
  'вирус',
  'вирша',
  'виска',
  'виски',
  'висок',
  'вистл',
  'висяк',
  'виток',
  'витье',
  'вихор',
  'вихрь',
  'вишня',
  'вклад',
  'влага',
  'воббл',
  'водка',
  'вожжа',
  'возжа',
  'возик',
  'возка',
  'возня',
  'возок',
  'войда',
  'война',
  'вокал',
  'волан',
  'волга',
  'волна',
  'волок',
  'волос',
  'волоф',
  'вольт',
  'волюм',
  'вопль',
  'ворга',
  'ворот',
  'ворох',
  'ворье',
  'вотив',
  'вотум',
  'вохра',
  'вошик',
  'впуск',
  'враки',
  'врата',
  'время',
  'вспых',
  'всток',
  'всход',
  'втора',
  'вуаль',
  'вуфер',
  'вчера',
  'въезд',
  'выбег',
  'выбой',
  'выбор',
  'вывал',
  'вывих',
  'вывод',
  'вывоз',
  'выгар',
  'выгиб',
  'выгон',
  'выгул',
  'выдел',
  'выдох',
  'выдув',
  'выдуй',
  'выезд',
  'выжиг',
  'выжим',
  'вызов',
  'выкат',
  'выкол',
  'выкос',
  'выкуп',
  'выкус',
  'вылаз',
  'вылет',
  'вылив',
  'вылов',
  'вылом',
  'вымол',
  'вымор',
  'вынос',
  'выпад',
  'выпал',
  'выпар',
  'выпас',
  'выпек',
  'выпор',
  'выпот',
  'выпук',
  'вырез',
  'вырий',
  'выруб',
  'высев',
  'высед',
  'высер',
  'вытье',
  'выход',
  'вышак',
  'вышиб',
  'вышка',
  'выщип',
  'вьюга',
  'вэйци',
  'вязка',
  'вязок',
  'вязье',
  'вялый',
  'габба',
  'гавар',
  'гавот',
  'гагат',
  'гадей',
  'гадик',
  'газер',
  'газик',
  'газок',
  'газон',
  'гайка',
  'гайно',
  'галда',
  'галит',
  'галка',
  'галла',
  'галоп',
  'галос',
  'галун',
  'галья',
  'галюн',
  'гамаз',
  'гамак',
  'гаман',
  'гамба',
  'гамма',
  'гамон',
  'гамут',
  'ганаш',
  'ганит',
  'гаочэ',
  'гараж',
  'гарда',
  'гарде',
  'гарем',
  'гарнц',
  'гарум',
  'гарус',
  'гаспс',
  'гауда',
  'гаусс',
  'гачек',
  'гашиш',
  'гвалт',
  'гваяк',
  'гевея',
  'гедза',
  'гейма',
  'гейша',
  'гекта',
  'гелий',
  'гелик',
  'гелит',
  'гелит',
  'гемин',
  'гемма',
  'гемор',
  'генез',
  'гений',
  'генин',
  'геном',
  'генри',
  'геоид',
  'герат',
  'герма',
  'герыч',
  'гетит',
  'гетра',
  'гетто',
  'гжель',
  'гибка',
  'гизмо',
  'гилас',
  'гилея',
  'гимен',
  'гимор',
  'гинея',
  'гиоид',
  'гипар',
  'гипюр',
  'гирка',
  'гирло',
  'гитов',
  'гифка',
  'гичка',
  'глава',
  'главк',
  'гладь',
  'глаза',
  'глайд',
  'глеба',
  'глефа',
  'глина',
  'глинт',
  'глиоз',
  'глитч',
  'глогг',
  'глуар',
  'глубь',
  'глузг',
  'глузд',
  'глушь',
  'глыба',
  'глыбь',
  'глюма',
  'глюон',
  'глясе',
  'гмина',
  'гнейс',
  'гнида',
  'гниль',
  'гноза',
  'гнома',
  'гнусь',
  'гобан',
  'гобой',
  'говно',
  'говор',
  'гоглы',
  'гогот',
  'годик',
  'годок',
  'голец',
  'голик',
  'голод',
  'голос',
  'голыш',
  'голье',
  'гольф',
  'гомар',
  'гомно',
  'гомон',
  'гонзо',
  'гонит',
  'гонка',
  'гонок',
  'гонор',
  'гопак',
  'горец',
  'горжа',
  'горит',
  'горка',
  'горло',
  'горно',
  'город',
  'горох',
  'горпо',
  'горст',
  'гофра',
  'гофре',
  'гохуа',
  'граве',
  'грайм',
  'грамм',
  'гранж',
  'грант',
  'грань',
  'граус',
  'графа',
  'гребь',
  'грежа',
  'греза',
  'грейд',
  'грена',
  'греча',
  'гриба',
  'грибы',
  'грива',
  'гридь',
  'гриль',
  'гринд',
  'гриот',
  'грипп',
  'грипс',
  'гроза',
  'гросс',
  'гроул',
  'груда',
  'грудь',
  'грунт',
  'груша',
  'грыжа',
  'грюйт',
  'гряда',
  'грязь',
  'гуава',
  'гуано',
  'гуашь',
  'губка',
  'губье',
  'гугня',
  'гугол',
  'гудеж',
  'гудок',
  'гужик',
  'гужир',
  'гузка',
  'гузно',
  'гуиро',
  'гуляш',
  'гумбо',
  'гумит',
  'гумма',
  'гумми',
  'гумно',
  'гумоз',
  'гумор',
  'гумус',
  'гунфу',
  'гурда',
  'гурий',
  'гурья',
  'гусак',
  'гусек',
  'гусле',
  'гусли',
  'гэбня',
  'гювеч',
  'дабке',
  'давеж',
  'давка',
  'дадан',
  'дайка',
  'далия',
  'дамба',
  'дамка',
  'дамно',
  'данго',
  'даний',
  'данса',
  'дартс',
  'дасия',
  'датер',
  'датив',
  'дафна',
  'дацан',
  'дацит',
  'дачка',
  'дверь',
  'двига',
  'двояк',
  'дебаг',
  'дебай',
  'дебет',
  'дебит',
  'дебош',
  'дебри',
  'дебют',
  'девиз',
  'девон',
  'дежка',
  'дезик',
  'деизм',
  'декан',
  'декор',
  'дележ',
  'делис',
  'демка',
  'демон',
  'демос',
  'денге',
  'денек',
  'деним',
  'денье',
  'депра',
  'дерба',
  'дерби',
  'дерен',
  'дерка',
  'дерма',
  'дерть',
  'дерть',
  'десна',
  'десть',
  'детва',
  'дефис',
  'джайв',
  'джанк',
  'джерк',
  'джига',
  'джила',
  'джины',
  'джоки',
  'дзета',
  'дзори',
  'дзюдо',
  'диада',
  'дибит',
  'диван',
  'дигук',
  'диета',
  'дилдо',
  'дилей',
  'дилен',
  'дильс',
  'димер',
  'динар',
  'динас',
  'динги',
  'динод',
  'дипин',
  'дипир',
  'дирит',
  'диско',
  'дифон',
  'дихта',
  'дичка',
  'дичок',
  'диэдр',
  'длань',
  'длина',
  'днище',
  'днюха',
  'добор',
  'добро',
  'довод',
  'довоз',
  'догма',
  'догри',
  'додзе',
  'додик',
  'додой',
  'доезд',
  'дождь',
  'дожиг',
  'дожим',
  'дозор',
  'дойка',
  'дойло',
  'дойна',
  'дойра',
  'докал',
  'долби',
  'долив',
  'долма',
  'домек',
  'домен',
  'домик',
  'домна',
  'домок',
  'домра',
  'донат',
  'донер',
  'донка',
  'донос',
  'донум',
  'донце',
  'допан',
  'дорги',
  'дорка',
  'досев',
  'доска',
  'досол',
  'досыл',
  'досье',
  'доход',
  'дочка',
  'дошик',
  'дошка',
  'драга',
  'драже',
  'драйв',
  'драка',
  'драма',
  'дрань',
  'драфт',
  'древо',
  'дреды',
  'дрейф',
  'дрель',
  'дрема',
  'дрена',
  'дринк',
  'дрись',
  'дрифт',
  'дробь',
  'дрова',
  'дрога',
  'дроги',
  'дрожь',
  'дроун',
  'друза',
  'дрызг',
  'дрына',
  'дрязг',
  'дрянь',
  'дуате',
  'дубак',
  'дубец',
  'дубка',
  'дубль',
  'дубок',
  'дубье',
  'дувал',
  'дуван',
  'дудеж',
  'дудка',
  'дудук',
  'дужка',
  'дукат',
  'думка',
  'дунам',
  'дунит',
  'дунст',
  'дуоль',
  'дуомо',
  'дупло',
  'дупль',
  'дурак',
  'дурит',
  'дурка',
  'дурра',
  'дурро',
  'дутар',
  'дутик',
  'дутый',
  'дутье',
  'духан',
  'душка',
  'душок',
  'дуэль',
  'дымка',
  'дымок',
  'дырка',
  'дыхло',
  'дышло',
  'дьюар',
  'дюбек',
  'дюкер',
  'дюрит',
  'дюшес',
  'ебало',
  'ебань',
  'ебель',
  'ебеня',
  'ебико',
  'еблет',
  'еблец',
  'ебота',
  'ебуки',
  'еврик',
  'едома',
  'едьба',
  'ездка',
  'елань',
  'елбан',
  'елдак',
  'елдач',
  'елина',
  'емейл',
  'емшан',
  'ерань',
  'ергак',
  'ересь',
  'ермос',
  'ерник',
  'ерник',
  'есень',
  'есеня',
  'еюнит',
  'жабка',
  'жабра',
  'жажда',
  'жакан',
  'жакет',
  'жакоб',
  'жалко',
  'жамка',
  'жарка',
  'жарок',
  'жатва',
  'жатка',
  'жвало',
  'жевка',
  'жевок',
  'желвь',
  'желоб',
  'желть',
  'желчь',
  'желчь',
  'жеода',
  'жердь',
  'жерло',
  'жесть',
  'жетон',
  'живот',
  'живье',
  'жиган',
  'жидва',
  'жижка',
  'жизнь',
  'жилет',
  'жилка',
  'жилье',
  'жирок',
  'жисть',
  'житие',
  'житье',
  'жменя',
  'жмудь',
  'жнива',
  'жниво',
  'жопка',
  'жопуа',
  'жудец',
  'жулье',
  'жумар',
  'жунта',
  'жупан',
  'жупел',
  'жупка',
  'жутик',
  'жучок',
  'забег',
  'забив',
  'забой',
  'забор',
  'завал',
  'завес',
  'завет',
  'завод',
  'завоз',
  'загад',
  'загар',
  'загиб',
  'загон',
  'загул',
  'задел',
  'задик',
  'задир',
  'задок',
  'задор',
  'заеба',
  'заеда',
  'заезд',
  'зажим',
  'зажин',
  'зажор',
  'зазор',
  'зазыв',
  'заказ',
  'закал',
  'закат',
  'закол',
  'закон',
  'закоп',
  'закос',
  'закус',
  'закут',
  'закят',
  'залаз',
  'залет',
  'залец',
  'залив',
  'зализ',
  'залог',
  'залом',
  'заман',
  'замах',
  'замер',
  'замес',
  'замет',
  'замин',
  'замок',
  'замор',
  'замот',
  'замша',
  'занде',
  'зандр',
  'занос',
  'запад',
  'запал',
  'запас',
  'запах',
  'запев',
  'запил',
  'запой',
  'запон',
  'запор',
  'зарев',
  'зарез',
  'зарик',
  'зарин',
  'зарод',
  'зарок',
  'заруб',
  'заряд',
  'засев',
  'засов',
  'засол',
  'засор',
  'засос',
  'засыл',
  'затар',
  'затек',
  'затес',
  'затея',
  'заток',
  'затон',
  'затор',
  'затык',
  'заумь',
  'заход',
  'зацеп',
  'зачес',
  'зачет',
  'зачин',
  'зашаг',
  'защеп',
  'защип',
  'заява',
  'звено',
  'звень',
  'зебра',
  'зевок',
  'зелье',
  'зельц',
  'земля',
  'зенит',
  'зенки',
  'зерно',
  'зернь',
  'зефир',
  'зилок',
  'зимка',
  'зипун',
  'зихер',
  'злато',
  'злоба',
  'знамя',
  'знать',
  'зобик',
  'золка',
  'зоман',
  'зооид',
  'зорит',
  'зраза',
  'зубец',
  'зубик',
  'зубок',
  'зудеж',
  'зулюм',
  'зумба',
  'зумпф',
  'зурна',
  'зушка',
  'зыбка',
  'зыбун',
  'зябра',
  'иббур',
  'ивент',
  'ивина',
  'ивняк',
  'иврит',
  'игиль',
  'игнор',
  'игрек',
  'идаит',
  'идеал',
  'иджма',
  'идиом',
  'ижица',
  'ижора',
  'извет',
  'извив',
  'извин',
  'извод',
  'извоз',
  'изгиб',
  'излад',
  'излет',
  'излог',
  'излом',
  'измол',
  'измор',
  'износ',
  'изъеб',
  'изъян',
  'изыск',
  'икаит',
  'икаль',
  'икона',
  'икота',
  'икряк',
  'иксия',
  'илеит',
  'илеус',
  'иллит',
  'ильма',
  'имаго',
  'имейл',
  'имидж',
  'инвар',
  'индел',
  'индий',
  'индит',
  'индол',
  'инжир',
  'инион',
  'инпут',
  'инста',
  'интим',
  'интро',
  'инула',
  'инфиз',
  'иодат',
  'иодид',
  'иодюр',
  'иолит',
  'ионий',
  'ионик',
  'ионит',
  'иприт',
  'ирмос',
  'искин',
  'ископ',
  'искра',
  'искус',
  'ислам',
  'испод',
  'испуг',
  'иссоп',
  'истод',
  'исток',
  'исход',
  'итоит',
  'ифика',
  'ихрам',
  'ихтис',
  'ишиас',
  'йидиш',
  'йодат',
  'йодид',
  'йодль',
  'йухня',
  'кабак',
  'кабул',
  'кавай',
  'кавал',
  'кавер',
  'кавун',
  'кагал',
  'кагат',
  'кагор',
  'кадаг',
  'кадар',
  'кадди',
  'кадка',
  'кадры',
  'кадык',
  'казан',
  'казна',
  'казнь',
  'казус',
  'кайен',
  'кайка',
  'кайла',
  'кайло',
  'кайма',
  'кайна',
  'какао',
  'калам',
  'калач',
  'калаш',
  'калий',
  'калла',
  'калпа',
  'калым',
  'калья',
  'калям',
  'камас',
  'камео',
  'камея',
  'камин',
  'камка',
  'камус',
  'камут',
  'камча',
  'камыш',
  'канал',
  'канат',
  'канва',
  'канзу',
  'канит',
  'канна',
  'канон',
  'каноэ',
  'канун',
  'капер',
  'капли',
  'капля',
  'капок',
  'капор',
  'капот',
  'каппа',
  'капри',
  'капут',
  'капча',
  'карат',
  'карвы',
  'карга',
  'карго',
  'карда',
  'карет',
  'кария',
  'карма',
  'карра',
  'карри',
  'карст',
  'карта',
  'карты',
  'карча',
  'карша',
  'касба',
  'каска',
  'каско',
  'касса',
  'каста',
  'катар',
  'катер',
  'катет',
  'катин',
  'катод',
  'каток',
  'катух',
  'катык',
  'катыш',
  'катях',
  'кауза',
  'каури',
  'кацея',
  'качим',
  'качка',
  'качок',
  'кашка',
  'кашне',
  'кашпо',
  'каюта',
  'квадр',
  'квала',
  'квант',
  'кварк',
  'кварц',
  'квача',
  'квест',
  'квилт',
  'квиют',
  'квота',
  'кебаб',
  'кегли',
  'кегль',
  'кегля',
  'кедик',
  'кеинг',
  'кекит',
  'кекур',
  'кекчи',
  'келия',
  'кельт',
  'келья',
  'кенаф',
  'кенеш',
  'кепка',
  'кербь',
  'керит',
  'керма',
  'кетен',
  'кетоз',
  'кетон',
  'кефир',
  'кечуа',
  'кешью',
  'кибла',
  'кибуц',
  'кивер',
  'кивок',
  'кивот',
  'кидок',
  'кизил',
  'кизяк',
  'кикер',
  'килик',
  'килим',
  'кильт',
  'кимчи',
  'кинез',
  'кинза',
  'киноа',
  'кинцо',
  'киоск',
  'кипеж',
  'кипер',
  'кипеш',
  'кипиш',
  'кирза',
  'кирка',
  'кирха',
  'кисва',
  'кисет',
  'кисея',
  'киска',
  'киста',
  'кисть',
  'кисэн',
  'китаб',
  'китит',
  'кифоз',
  'кичка',
  'кишка',
  'кишки',
  'клава',
  'клада',
  'кладь',
  'клака',
  'клапы',
  'кларк',
  'класс',
  'клатч',
  'клерс',
  'клеть',
  'клещи',
  'клика',
  'клико',
  'клинч',
  'клипс',
  'клица',
  'клише',
  'клоак',
  'клоке',
  'клуня',
  'клупп',
  'клюка',
  'кнель',
  'кнехт',
  'книга',
  'кница',
  'кнорр',
  'кобел',
  'кобея',
  'кобза',
  'кобол',
  'кобуз',
  'кобыз',
  'ковар',
  'ковер',
  'ковид',
  'ковит',
  'ковка',
  'кодак',
  'кодек',
  'кодер',
  'кодия',
  'кодла',
  'кодон',
  'кожан',
  'кожух',
  'кожье',
  'козан',
  'козлы',
  'козны',
  'кознь',
  'козон',
  'коикс',
  'койка',
  'койма',
  'койне',
  'койнэ',
  'койол',
  'кокле',
  'кокни',
  'кокон',
  'кокор',
  'кокос',
  'кокус',
  'колба',
  'колер',
  'колет',
  'колея',
  'колик',
  'колит',
  'колка',
  'колоб',
  'колок',
  'колон',
  'колос',
  'колот',
  'колун',
  'колча',
  'колье',
  'колье',
  'кольт',
  'колюр',
  'комби',
  'комбо',
  'комма',
  'комми',
  'комод',
  'комок',
  'конга',
  'конго',
  'конек',
  'конец',
  'конид',
  'коник',
  'конка',
  'конте',
  'конто',
  'конус',
  'конфа',
  'конха',
  'копал',
  'копер',
  'копец',
  'копие',
  'копир',
  'копия',
  'копка',
  'копна',
  'коппа',
  'копра',
  'копыл',
  'копье',
  'корда',
  'корец',
  'корка',
  'корма',
  'короб',
  'корча',
  'корье',
  'коска',
  'космы',
  'косок',
  'кости',
  'кость',
  'косье',
  'косяк',
  'котел',
  'котип',
  'котлы',
  'котух',
  'кофей',
  'кофий',
  'кофта',
  'кохит',
  'кохия',
  'кочан',
  'кочка',
  'кошер',
  'кошка',
  'кошма',
  'крабб',
  'крага',
  'кража',
  'краза',
  'крамп',
  'кранк',
  'крант',
  'кранц',
  'кранч',
  'крапп',
  'краса',
  'краст',
  'крафт',
  'кредо',
  'крема',
  'крень',
  'крепь',
  'кресс',
  'крест',
  'крига',
  'криль',
  'крими',
  'кринж',
  'крица',
  'кровь',
  'кроки',
  'кроль',
  'крона',
  'кросс',
  'крота',
  'кроха',
  'кроше',
  'круиз',
  'крупа',
  'круть',
  'круча',
  'кручь',
  'крыга',
  'крыло',
  'крыса',
  'крыша',
  'крэпс',
  'ксень',
  'ксива',
  'ксист',
  'кубик',
  'кубит',
  'кубло',
  'кубок',
  'кувез',
  'кудла',
  'кудри',
  'кузня',
  'кузов',
  'кукан',
  'кукиш',
  'кукла',
  'кукож',
  'кукри',
  'куксу',
  'кукуи',
  'кулак',
  'кулек',
  'кулер',
  'кулеш',
  'кулич',
  'кулон',
  'кулье',
  'культ',
  'куман',
  'кумар',
  'кумач',
  'кумин',
  'кумир',
  'кумол',
  'кумуз',
  'кумыс',
  'кунка',
  'кунфу',
  'купаж',
  'купля',
  'купол',
  'купон',
  'кураж',
  'курай',
  'курак',
  'кураш',
  'курес',
  'куреш',
  'курзе',
  'курия',
  'курка',
  'курок',
  'курос',
  'курта',
  'курум',
  'курур',
  'куруш',
  'курья',
  'кусок',
  'кутаб',
  'кутан',
  'кутас',
  'кутеж',
  'кутин',
  'кутис',
  'куток',
  'кутра',
  'кутья',
  'кутюм',
  'куфия',
  'кухва',
  'кухен',
  'кухня',
  'кухта',
  'кучка',
  'кушак',
  'кэмпо',
  'кэндо',
  'кэроб',
  'кэтти',
  'кювет',
  'кюлот',
  'кюрий',
  'кюрит',
  'кюфта',
  'кяриз',
  'кясра',
  'лабаз',
  'лаваж',
  'лаваш',
  'лавда',
  'лавис',
  'лавка',
  'лавра',
  'лагер',
  'лагун',
  'ладан',
  'ладка',
  'ладок',
  'ладья',
  'лазер',
  'лазея',
  'лазок',
  'лайба',
  'лайда',
  'лайка',
  'лакаб',
  'лакса',
  'лалка',
  'ламба',
  'ламин',
  'лампа',
  'ланда',
  'ландо',
  'лапка',
  'лапта',
  'лапша',
  'ларва',
  'лардо',
  'ларек',
  'ларец',
  'ласка',
  'ласси',
  'лассо',
  'ласта',
  'латка',
  'латте',
  'латук',
  'лаунж',
  'лафет',
  'лафит',
  'лацци',
  'лаццо',
  'лачок',
  'лбина',
  'лбище',
  'левак',
  'левел',
  'легуа',
  'ледок',
  'лежак',
  'лежка',
  'лейас',
  'лейбл',
  'лейка',
  'лейма',
  'лекиф',
  'лекса',
  'лемех',
  'лемма',
  'ленок',
  'лента',
  'ленца',
  'леоне',
  'лепет',
  'лепка',
  'лепра',
  'лепта',
  'лерка',
  'леска',
  'лесок',
  'лесть',
  'лесха',
  'летка',
  'летка',
  'леток',
  'лжеца',
  'лжица',
  'лиаза',
  'лиана',
  'либра',
  'ливан',
  'ливер',
  'лидар',
  'лизат',
  'лизин',
  'лизис',
  'лизол',
  'лизун',
  'ликер',
  'ликий',
  'лилея',
  'лилия',
  'лиман',
  'лимбо',
  'лимит',
  'лимон',
  'лимфа',
  'линга',
  'линек',
  'линер',
  'линза',
  'линия',
  'линол',
  'липец',
  'липид',
  'липка',
  'липси',
  'литва',
  'литер',
  'литий',
  'лития',
  'литка',
  'литон',
  'литра',
  'литье',
  'лифак',
  'лифон',
  'лихва',
  'лихен',
  'лицей',
  'личка',
  'лишай',
  'лишек',
  'лишко',
  'лобби',
  'лобик',
  'лобио',
  'лобок',
  'ловля',
  'логин',
  'логос',
  'лоден',
  'лодка',
  'ложка',
  'ложок',
  'локва',
  'локер',
  'локон',
  'локус',
  'ломик',
  'ломка',
  'ломок',
  'лонжа',
  'лопух',
  'лорум',
  'лоток',
  'лотос',
  'лофер',
  'лохма',
  'лохмы',
  'лохос',
  'лоция',
  'лубок',
  'лужок',
  'лузга',
  'лукно',
  'лукум',
  'лунда',
  'лунит',
  'лунка',
  'лупин',
  'лупка',
  'луффа',
  'лучик',
  'лучок',
  'лыдка',
  'лыжня',
  'лыска',
  'лысто',
  'лытка',
  'лытки',
  'лычка',
  'лычко',
  'льяло',
  'любка',
  'люгер',
  'людус',
  'люмен',
  'люнет',
  'люпин',
  'люпус',
  'люстр',
  'лютик',
  'лютня',
  'люффа',
  'лючок',
  'ляган',
  'ляжка',
  'лямка',
  'ляпис',
  'ляссе',
  'магаз',
  'магей',
  'магия',
  'магма',
  'мадия',
  'маета',
  'мажор',
  'мазар',
  'мазда',
  'мазер',
  'мазик',
  'мазка',
  'мазня',
  'мазок',
  'мазут',
  'майка',
  'майна',
  'макао',
  'макар',
  'макет',
  'макса',
  'макси',
  'малик',
  'малка',
  'мальм',
  'мамба',
  'мамбо',
  'мамон',
  'мамсы',
  'манас',
  'манат',
  'манга',
  'манго',
  'мангр',
  'манда',
  'манду',
  'манеж',
  'манер',
  'мание',
  'мания',
  'манка',
  'манки',
  'манко',
  'манна',
  'манок',
  'манор',
  'манта',
  'манто',
  'манту',
  'манты',
  'манье',
  'марго',
  'маржа',
  'марка',
  'марля',
  'марма',
  'марши',
  'маска',
  'масло',
  'масса',
  'масть',
  'матан',
  'матка',
  'матюг',
  'матюк',
  'мафит',
  'мафия',
  'мафон',
  'махач',
  'махра',
  'махры',
  'мацис',
  'мацун',
  'мачта',
  'машта',
  'мегом',
  'медиа',
  'медий',
  'медин',
  'медок',
  'медье',
  'медяк',
  'мезга',
  'мезил',
  'мезон',
  'мейоз',
  'мелия',
  'мелок',
  'мелос',
  'мемас',
  'мемес',
  'мемос',
  'менди',
  'менжа',
  'менка',
  'мерва',
  'мерин',
  'мерка',
  'мером',
  'месса',
  'место',
  'месть',
  'месяц',
  'метал',
  'метан',
  'метиз',
  'метил',
  'метин',
  'метка',
  'метла',
  'метод',
  'метол',
  'метоп',
  'метро',
  'меццо',
  'мечта',
  'мешка',
  'мешок',
  'миазм',
  'мивал',
  'мигма',
  'мизер',
  'микоз',
  'микст',
  'милиа',
  'милка',
  'милон',
  'минет',
  'минея',
  'миник',
  'минор',
  'минус',
  'миома',
  'мираж',
  'мирка',
  'мирок',
  'мирра',
  'миска',
  'митап',
  'митоз',
  'митра',
  'млеко',
  'мнема',
  'моаре',
  'могар',
  'могул',
  'модал',
  'модем',
  'модий',
  'модус',
  'мозги',
  'моизм',
  'мойка',
  'мокап',
  'мокик',
  'мокко',
  'мокша',
  'молва',
  'молвь',
  'молка',
  'молот',
  'молян',
  'моляр',
  'момме',
  'моммэ',
  'мондо',
  'моник',
  'моном',
  'мопед',
  'морда',
  'морин',
  'морит',
  'мория',
  'мороз',
  'морок',
  'морфа',
  'морцо',
  'моряк',
  'мосол',
  'мотет',
  'мотив',
  'мотик',
  'мотка',
  'мотня',
  'моток',
  'мотор',
  'мотто',
  'мофет',
  'мохер',
  'мохит',
  'мохны',
  'мочка',
  'мошка',
  'мошна',
  'мразь',
  'мрежа',
  'мреть',
  'муаре',
  'мугам',
  'мудня',
  'мудра',
  'музга',
  'музей',
  'музло',
  'музон',
  'мульт',
  'муляж',
  'мумие',
  'мумия',
  'мунгу',
  'мурал',
  'мурит',
  'мурло',
  'мурти',
  'мурья',
  'мусат',
  'мусор',
  'мутон',
  'мутул',
  'муфта',
  'муцин',
  'мучка',
  'мушка',
  'мшара',
  'мшель',
  'мысик',
  'мысль',
  'мысок',
  'мытня',
  'мытье',
  'мычка',
  'мышей',
  'мышка',
  'мышца',
  'мэлан',
  'мэрия',
  'мэрон',
  'мэшап',
  'мюзет',
  'мюзле',
  'мякиш',
  'мялка',
  'мянда',
  'мяско',
  'мясцо',
  'мятеж',
  'мятка',
  'мятье',
  'мячик',
  'набат',
  'набег',
  'набка',
  'набла',
  'набой',
  'набор',
  'навал',
  'навар',
  'навес',
  'навет',
  'навий',
  'навис',
  'навоз',
  'навой',
  'навык',
  'наган',
  'нагар',
  'нагиб',
  'нагон',
  'нагул',
  'надел',
  'надир',
  'надой',
  'надом',
  'надув',
  'наезд',
  'нажим',
  'нажин',
  'назем',
  'найки',
  'найра',
  'наказ',
  'накал',
  'накат',
  'накид',
  'накоп',
  'накос',
  'накры',
  'налеп',
  'налет',
  'налив',
  'налик',
  'налог',
  'налой',
  'намаз',
  'намек',
  'намет',
  'намин',
  'намол',
  'намыв',
  'нанга',
  'нанка',
  'наноб',
  'нанос',
  'напас',
  'напев',
  'напой',
  'напор',
  'наппа',
  'нарды',
  'нарез',
  'народ',
  'нарта',
  'нарты',
  'нарыв',
  'наряд',
  'насад',
  'насос',
  'насып',
  'натек',
  'натес',
  'натяг',
  'наука',
  'науру',
  'нафта',
  'нахия',
  'наход',
  'нация',
  'начал',
  'начес',
  'начет',
  'начин',
  'начос',
  'нгала',
  'небел',
  'невма',
  'невод',
  'невус',
  'недра',
  'недуг',
  'нейит',
  'нения',
  'непер',
  'нерол',
  'неуют',
  'нефеш',
  'нефть',
  'нечет',
  'нивка',
  'нивоз',
  'низка',
  'низок',
  'никаб',
  'нилит',
  'нимит',
  'нисан',
  'нитка',
  'нитон',
  'ничья',
  'нишка',
  'нияма',
  'нобль',
  'новое',
  'новье',
  'нодья',
  'ножик',
  'ножка',
  'ножны',
  'нолик',
  'номен',
  'номер',
  'номос',
  'нонет',
  'норит',
  'нория',
  'норка',
  'норма',
  'норов',
  'норос',
  'носик',
  'носка',
  'носок',
  'нотис',
  'нотка',
  'ночвы',
  'ночка',
  'ношпа',
  'ноэль',
  'нравы',
  'нубук',
  'нудик',
  'нужда',
  'нулик',
  'нумен',
  'нумер',
  'нураг',
  'нутро',
  'нынлу',
  'нырок',
  'нытье',
  'нычка',
  'нэнго',
  'нэцке',
  'нэцкэ',
  'нюанс',
  'оазис',
  'обвал',
  'обвес',
  'обвод',
  'обвоз',
  'обгон',
  'обдир',
  'обдув',
  'обедь',
  'обжиг',
  'обжим',
  'обжин',
  'обжог',
  'обзол',
  'обзор',
  'обида',
  'обкат',
  'обком',
  'обкоп',
  'обкос',
  'облак',
  'облас',
  'облет',
  'облив',
  'облик',
  'облов',
  'облог',
  'облой',
  'облом',
  'обман',
  'обмен',
  'обмер',
  'обмет',
  'обмин',
  'обмол',
  'обмыв',
  'обнал',
  'обнос',
  'обора',
  'образ',
  'обрат',
  'обрез',
  'обрис',
  'оброк',
  'оброн',
  'обруб',
  'обруч',
  'обрыв',
  'обряд',
  'обсев',
  'обсер',
  'обувь',
  'обужа',
  'обуза',
  'обход',
  'общак',
  'общее',
  'объеб',
  'объем',
  'обыск',
  'овоид',
  'овраг',
  'овсец',
  'овсюг',
  'овуль',
  'огива',
  'огляд',
  'огонь',
  'огрех',
  'одаль',
  'оддит',
  'одежа',
  'одеон',
  'одурь',
  'оелит',
  'ожина',
  'озеро',
  'озимь',
  'озноб',
  'оидий',
  'оидия',
  'ойлер',
  'окаем',
  'океан',
  'окись',
  'окиян',
  'оклад',
  'оклик',
  'оковы',
  'окоем',
  'окорм',
  'окрас',
  'окрик',
  'окрол',
  'округ',
  'оксид',
  'оксим',
  'оксия',
  'октал',
  'октан',
  'октен',
  'октет',
  'октил',
  'олеат',
  'олеин',
  'олеум',
  'олива',
  'олимп',
  'олифа',
  'олово',
  'олтар',
  'ольха',
  'омаха',
  'омега',
  'омежь',
  'омела',
  'омлет',
  'оммаж',
  'омрак',
  'омфал',
  'онагр',
  'оникс',
  'онсэн',
  'онуча',
  'оншор',
  'оолит',
  'ооцит',
  'опала',
  'опаль',
  'опара',
  'опека',
  'опель',
  'опера',
  'опиан',
  'опиат',
  'опись',
  'опиум',
  'оплот',
  'оплыв',
  'опоек',
  'опока',
  'опона',
  'опора',
  'опрос',
  'опхоз',
  'опция',
  'орава',
  'орала',
  'орале',
  'орало',
  'орарь',
  'орган',
  'оргия',
  'орден',
  'ордер',
  'орево',
  'ореол',
  'оржад',
  'орисс',
  'оркан',
  'орлец',
  'орлон',
  'орляк',
  'орсин',
  'ортез',
  'ортет',
  'ортит',
  'орхит',
  'оршад',
  'осада',
  'осень',
  'осина',
  'оскал',
  'ослоп',
  'осмий',
  'осмол',
  'осмос',
  'осока',
  'остав',
  'остан',
  'остин',
  'остит',
  'остов',
  'остол',
  'остюк',
  'осыпь',
  'отава',
  'отара',
  'отбег',
  'отбив',
  'отбой',
  'отбор',
  'отвал',
  'отвар',
  'отвес',
  'ответ',
  'отвод',
  'отвоз',
  'отгиб',
  'отгон',
  'отгул',
  'отдел',
  'отдух',
  'отдых',
  'отель',
  'отжиг',
  'отжим',
  'отзол',
  'отзыв',
  'отказ',
  'откат',
  'откол',
  'откос',
  'откуп',
  'откус',
  'отлас',
  'отлет',
  'отлив',
  'отлип',
  'отлов',
  'отлог',
  'отлом',
  'отлуп',
  'отмаз',
  'отмах',
  'отмер',
  'отмыв',
  'относ',
  'отпад',
  'отпал',
  'отпор',
  'отрез',
  'отрог',
  'отруб',
  'отрыв',
  'отряд',
  'отсев',
  'отсек',
  'отсос',
  'отсыл',
  'отсып',
  'оттек',
  'отток',
  'оттяг',
  'отход',
  'отцеп',
  'отчал',
  'отчет',
  'отшиб',
  'отщеп',
  'отъем',
  'офорт',
  'офсет',
  'офуро',
  'оффер',
  'офшор',
  'охват',
  'охлос',
  'охлуп',
  'охота',
  'очерк',
  'очкур',
  'ошеек',
  'ощупь',
  'пагон',
  'паддл',
  'падеж',
  'падеж',
  'падик',
  'падло',
  'падма',
  'падог',
  'падра',
  'падуб',
  'падун',
  'пазик',
  'пазок',
  'пайва',
  'пайза',
  'пайка',
  'пайол',
  'пайса',
  'пайта',
  'пакер',
  'пакет',
  'пакля',
  'пакша',
  'палас',
  'палау',
  'палац',
  'палаш',
  'палех',
  'палец',
  'палея',
  'палий',
  'палка',
  'палуб',
  'пампа',
  'панаш',
  'панда',
  'панер',
  'панна',
  'панно',
  'панты',
  'панье',
  'папка',
  'парад',
  'параф',
  'парез',
  'парео',
  'парик',
  'парка',
  'парма',
  'парод',
  'парок',
  'паром',
  'парта',
  'парти',
  'парун',
  'парус',
  'паруш',
  'парфе',
  'парча',
  'парша',
  'паршь',
  'парья',
  'пасик',
  'паска',
  'пасма',
  'пасмо',
  'пасок',
  'паста',
  'пасть',
  'пасха',
  'паташ',
  'патио',
  'патка',
  'патлы',
  'пауза',
  'паунд',
  'пафос',
  'пахви',
  'пахвы',
  'пахит',
  'пахта',
  'пачка',
  'пашня',
  'пашот',
  'педик',
  'пейот',
  'пекан',
  'пекло',
  'пекло',
  'пелит',
  'пемза',
  'пенал',
  'пенге',
  'пенго',
  'пенек',
  'пение',
  'пенис',
  'пенка',
  'пенне',
  'пенни',
  'пенье',
  'пепел',
  'пепси',
  'перга',
  'пердь',
  'перед',
  'перед',
  'перец',
  'перит',
  'перка',
  'пермь',
  'перри',
  'перси',
  'перст',
  'перун',
  'перье',
  'песец',
  'песнь',
  'песня',
  'песок',
  'песто',
  'петие',
  'петит',
  'петля',
  'петье',
  'печит',
  'печка',
  'пешка',
  'пешня',
  'пещер',
  'пещур',
  'пиала',
  'пиано',
  'пивас',
  'пивко',
  'пивцо',
  'пигус',
  'пижма',
  'пижня',
  'пизда',
  'пикан',
  'пикап',
  'пикет',
  'пикон',
  'пикси',
  'пикча',
  'пилав',
  'пилка',
  'пилон',
  'пилот',
  'пилум',
  'пилус',
  'пимпа',
  'пинен',
  'пиния',
  'пинка',
  'пинна',
  'пинок',
  'пинта',
  'пипец',
  'пипис',
  'пипка',
  'пиран',
  'пирит',
  'пирке',
  'пирог',
  'пирок',
  'пирон',
  'пироп',
  'писко',
  'писюк',
  'писюн',
  'питие',
  'питон',
  'питье',
  'пифос',
  'пихта',
  'пицца',
  'пичок',
  'пищик',
  'плавь',
  'плаза',
  'пламя',
  'пласт',
  'плата',
  'плато',
  'плаун',
  'плаха',
  'плебс',
  'плева',
  'плеер',
  'плейт',
  'племя',
  'плена',
  'плеск',
  'плесо',
  'плеть',
  'плечо',
  'плешь',
  'плинт',
  'плита',
  'плица',
  'плока',
  'плоть',
  'плюха',
  'пнище',
  'побег',
  'побои',
  'побор',
  'побыт',
  'повал',
  'повер',
  'повет',
  'повод',
  'повоз',
  'повой',
  'повят',
  'погиб',
  'погон',
  'подел',
  'подий',
  'подог',
  'подой',
  'подок',
  'подол',
  'поедь',
  'поезд',
  'пожар',
  'пожня',
  'пожог',
  'позем',
  'позор',
  'позыв',
  'поиск',
  'пойка',
  'пойло',
  'пойма',
  'показ',
  'покал',
  'покат',
  'покер',
  'покои',
  'покой',
  'покол',
  'покон',
  'покор',
  'покос',
  'покус',
  'полба',
  'полет',
  'полив',
  'полис',
  'полка',
  'полог',
  'полоз',
  'полой',
  'полок',
  'полом',
  'полон',
  'полть',
  'полюс',
  'поляр',
  'помет',
  'помин',
  'помои',
  'помол',
  'помпа',
  'понор',
  'понос',
  'понур',
  'пончо',
  'понюх',
  'попас',
  'попец',
  'попка',
  'попса',
  'попыт',
  'порез',
  'порей',
  'порка',
  'порно',
  'порог',
  'порок',
  'порох',
  'порск',
  'порто',
  'порты',
  'поруб',
  'поруш',
  'порча',
  'порше',
  'порыв',
  'посад',
  'посев',
  'посол',
  'посох',
  'посул',
  'посыл',
  'поташ',
  'потек',
  'потик',
  'потир',
  'поток',
  'потоп',
  'потяг',
  'поход',
  'почан',
  'почва',
  'почет',
  'почин',
  'почка',
  'почта',
  'пошет',
  'пошиб',
  'пошив',
  'поэза',
  'поэма',
  'право',
  'прайд',
  'прайм',
  'прайс',
  'прана',
  'пранк',
  'праща',
  'преза',
  'прель',
  'преон',
  'пресс',
  'прием',
  'прима',
  'принт',
  'прион',
  'приуз',
  'причт',
  'приют',
  'проба',
  'прово',
  'прога',
  'прода',
  'проеб',
  'проем',
  'проза',
  'промо',
  'пропс',
  'просо',
  'проух',
  'пруха',
  'пршут',
  'прыск',
  'прыть',
  'прядь',
  'пряжа',
  'псили',
  'псина',
  'псион',
  'псише',
  'псора',
  'пуант',
  'пуаре',
  'пугач',
  'пудик',
  'пудра',
  'пузцо',
  'пукан',
  'пукля',
  'пулен',
  'пульс',
  'пульт',
  'пунец',
  'пункт',
  'пупок',
  'пурга',
  'пурда',
  'пурин',
  'пурка',
  'пусет',
  'путец',
  'путик',
  'путин',
  'путло',
  'путти',
  'пуфик',
  'пуцин',
  'пучок',
  'пушка',
  'пушок',
  'пушту',
  'пшено',
  'пыжик',
  'пырей',
  'пырка',
  'пытка',
  'пышка',
  'пьеза',
  'пьеса',
  'пьянь',
  'пялка',
  'пянсе',
  'пясть',
  'пятак',
  'пятка',
  'пятно',
  'пяток',
  'рабат',
  'рагга',
  'радар',
  'радий',
  'радио',
  'радон',
  'разер',
  'разик',
  'разок',
  'разор',
  'разум',
  'раина',
  'райда',
  'райзо',
  'район',
  'райта',
  'райфо',
  'ракия',
  'ракля',
  'ралли',
  'рамен',
  'рамет',
  'рамка',
  'рампа',
  'рамэн',
  'ранет',
  'ранец',
  'ранка',
  'ранчо',
  'ранье',
  'ранье',
  'рапид',
  'растр',
  'ратин',
  'ратит',
  'ратти',
  'раунд',
  'рафид',
  'рафик',
  'рафия',
  'рахис',
  'рахит',
  'рацея',
  'рацио',
  'рация',
  'рачня',
  'рвань',
  'рвота',
  'рдест',
  'ребаб',
  'ребай',
  'ребек',
  'ребро',
  'ребус',
  'ребут',
  'ревир',
  'ревка',
  'ревун',
  'ревью',
  'реган',
  'регат',
  'регби',
  'регги',
  'регот',
  'редан',
  'редис',
  'редиф',
  'редия',
  'редут',
  'режик',
  'режим',
  'резак',
  'резен',
  'резец',
  'резит',
  'резка',
  'резня',
  'резол',
  'резон',
  'резус',
  'рейда',
  'рейка',
  'рейки',
  'рекап',
  'рекон',
  'ректо',
  'релиз',
  'релин',
  'релка',
  'релон',
  'рельс',
  'ремеш',
  'ремиз',
  'ренат',
  'ренет',
  'рений',
  'ренин',
  'ренит',
  'рента',
  'репей',
  'репер',
  'репит',
  'репка',
  'репье',
  'ретро',
  'ретэш',
  'рехаб',
  'речка',
  'решка',
  'решти',
  'ржавь',
  'ржака',
  'ржище',
  'рибай',
  'рибоп',
  'ривер',
  'ригид',
  'ридер',
  'риель',
  'ризка',
  'рилин',
  'ример',
  'ринда',
  'ринит',
  'риска',
  'ритон',
  'рифей',
  'рифля',
  'рифма',
  'рицин',
  'ровер',
  'ровик',
  'рогоз',
  'рогор',
  'родан',
  'родео',
  'родер',
  'родий',
  'родня',
  'рожки',
  'рожно',
  'рожок',
  'рожон',
  'розан',
  'розга',
  'рознь',
  'ройба',
  'ройка',
  'рокот',
  'ролик',
  'роман',
  'ромец',
  'ромок',
  'ромша',
  'рондо',
  'ропак',
  'ропот',
  'ростр',
  'ротик',
  'роток',
  'ротон',
  'ротор',
  'рохля',
  'рояль',
  'ртище',
  'ртуть',
  'рубаб',
  'рубаи',
  'рубас',
  'рубеж',
  'рубеж',
  'рубец',
  'рубин',
  'рубка',
  'рубль',
  'рубоб',
  'ругня',
  'рудит',
  'рудяк',
  'ружье',
  'руина',
  'рукав',
  'рулеж',
  'рулет',
  'рулон',
  'румба',
  'рунди',
  'рупия',
  'рупор',
  'русаб',
  'русин',
  'русло',
  'русня',
  'рутер',
  'рутил',
  'рутин',
  'ручей',
  'ручка',
  'рушка',
  'рывок',
  'рыжий',
  'рыжик',
  'рыжье',
  'рында',
  'рынок',
  'рысца',
  'рытье',
  'рычаг',
  'рэйит',
  'рэйки',
  'рэкет',
  'рэлей',
  'рэмбо',
  'рэнга',
  'рюинь',
  'рюмка',
  'рюшка',
  'рябик',
  'рядно',
  'рядок',
  'ряска',
  'рясна',
  'ряшка',
  'сабан',
  'сабже',
  'сабза',
  'сабля',
  'сабур',
  'саван',
  'савар',
  'сават',
  'сагум',
  'садик',
  'садка',
  'садно',
  'садок',
  'сайка',
  'сайкл',
  'сайма',
  'саква',
  'сакля',
  'сакма',
  'сакта',
  'салад',
  'салат',
  'салит',
  'салки',
  'салма',
  'салол',
  'салон',
  'салоп',
  'салун',
  'салют',
  'салят',
  'саман',
  'самба',
  'самбо',
  'самоа',
  'самса',
  'самум',
  'санбо',
  'санго',
  'санди',
  'санки',
  'сапка',
  'сапог',
  'сапун',
  'сарай',
  'саран',
  'саржа',
  'сарма',
  'сарон',
  'сарос',
  'сатин',
  'сауна',
  'саунд',
  'сафра',
  'сахар',
  'сачок',
  'сблев',
  'сбора',
  'сброд',
  'сброс',
  'сбруя',
  'свада',
  'свази',
  'свайп',
  'свара',
  'свати',
  'свеит',
  'сверк',
  'свеча',
  'свеща',
  'свиль',
  'свинг',
  'свист',
  'свита',
  'свитч',
  'свора',
  'связа',
  'связь',
  'сглаз',
  'сдача',
  'сдвиг',
  'сдело',
  'сдоба',
  'сеанс',
  'себум',
  'севба',
  'север',
  'севок',
  'седан',
  'седзе',
  'седзи',
  'седло',
  'сеево',
  'сезам',
  'сезон',
  'сейба',
  'сейша',
  'секас',
  'секач',
  'секис',
  'секта',
  'селен',
  'селин',
  'селфи',
  'селям',
  'семик',
  'семис',
  'семка',
  'семпл',
  'семья',
  'сенаж',
  'сенат',
  'сенет',
  'сенки',
  'сенна',
  'сенцо',
  'сенцы',
  'сенэн',
  'сепия',
  'септа',
  'серак',
  'сердж',
  'серик',
  'серин',
  'серир',
  'сериф',
  'серия',
  'серка',
  'серсо',
  'серум',
  'серяк',
  'сетап',
  'сетка',
  'сечка',
  'сиаль',
  'сивер',
  'сигил',
  'сигма',
  'сигна',
  'сиджо',
  'сидка',
  'сидор',
  'сидюк',
  'сиена',
  'сижка',
  'сикль',
  'сикоз',
  'силан',
  'силат',
  'силка',
  'силок',
  'силон',
  'силос',
  'силур',
  'силье',
  'симка',
  'синап',
  'сингл',
  'синий',
  'синод',
  'синто',
  'синус',
  'синяк',
  'сироп',
  'систр',
  'ситар',
  'ситец',
  'ситро',
  'ситце',
  'сифон',
  'скайп',
  'скайс',
  'скала',
  'скало',
  'скалы',
  'скань',
  'скарб',
  'скарн',
  'скаут',
  'скачь',
  'сквад',
  'сквер',
  'сквид',
  'сквот',
  'сквош',
  'скейт',
  'скена',
  'скенэ',
  'скетч',
  'скилл',
  'скирд',
  'склад',
  'склеп',
  'склеп',
  'склиз',
  'склон',
  'скоба',
  'скора',
  'скорм',
  'скотч',
  'скраб',
  'скрад',
  'скрап',
  'скреп',
  'скрин',
  'скрип',
  'скрэб',
  'скуба',
  'скуда',
  'скуди',
  'скудо',
  'скука',
  'скула',
  'слава',
  'сладж',
  'слайд',
  'слайм',
  'слайс',
  'слань',
  'слега',
  'слеза',
  'сленг',
  'слива',
  'слизь',
  'слинг',
  'слипы',
  'слова',
  'слово',
  'слоек',
  'слюда',
  'слюна',
  'слюни',
  'смазь',
  'смайл',
  'смарт',
  'смена',
  'смерч',
  'смесь',
  'смета',
  'смоки',
  'смола',
  'смоль',
  'смотр',
  'смрад',
  'смска',
  'смузи',
  'смурь',
  'смута',
  'смуть',
  'смысл',
  'снафф',
  'снедь',
  'снейк',
  'снитч',
  'снить',
  'сноси',
  'сныть',
  'собес',
  'собор',
  'совет',
  'совик',
  'совок',
  'согра',
  'соджу',
  'содом',
  'созыв',
  'сойма',
  'сокер',
  'сокет',
  'сокол',
  'солен',
  'солея',
  'солид',
  'солка',
  'солод',
  'солоп',
  'соляк',
  'соляр',
  'сомит',
  'сомма',
  'сомон',
  'сонар',
  'сонет',
  'сопка',
  'сопло',
  'сопля',
  'сопор',
  'сорбе',
  'сорго',
  'сорит',
  'сорок',
  'сором',
  'сорус',
  'сосец',
  'соска',
  'сосна',
  'сосок',
  'сосуд',
  'сотая',
  'сотик',
  'сотка',
  'сотня',
  'софит',
  'сохра',
  'сошка',
  'спаги',
  'спазм',
  'спайк',
  'спайс',
  'спект',
  'спесь',
  'спиды',
  'спина',
  'спирт',
  'спица',
  'сплав',
  'сплин',
  'сплит',
  'сплиф',
  'сплог',
  'спонж',
  'спора',
  'спорт',
  'спред',
  'спрей',
  'спрос',
  'спрут',
  'спума',
  'спурт',
  'спуск',
  'спюрк',
  'срака',
  'срань',
  'среда',
  'ссака',
  'ссора',
  'ссуда',
  'ссыль',
  'стадо',
  'сталь',
  'станс',
  'стант',
  'старт',
  'старь',
  'стата',
  'стать',
  'стаут',
  'ствол',
  'створ',
  'стега',
  'стежь',
  'стезя',
  'стейк',
  'стека',
  'стела',
  'стелс',
  'стель',
  'стена',
  'стенд',
  'стент',
  'степс',
  'степь',
  'стило',
  'стиль',
  'сткло',
  'стокс',
  'стола',
  'столб',
  'столп',
  'стома',
  'стопа',
  'стора',
  'стоун',
  'стояк',
  'страз',
  'страх',
  'стрел',
  'стрем',
  'стрик',
  'стрим',
  'стрип',
  'стрит',
  'строб',
  'строй',
  'строп',
  'струг',
  'струп',
  'струя',
  'стужа',
  'ступа',
  'стынь',
  'суаре',
  'сувой',
  'судно',
  'судок',
  'суета',
  'суинг',
  'сукка',
  'сукно',
  'сукре',
  'сулея',
  'сулой',
  'сулук',
  'сулык',
  'сумах',
  'сумет',
  'сумка',
  'сумма',
  'сунна',
  'супат',
  'супер',
  'супец',
  'супин',
  'суржа',
  'сурик',
  'сурит',
  'сурма',
  'сурна',
  'сусак',
  'сусек',
  'сусло',
  'сутаж',
  'сутки',
  'сутра',
  'суфле',
  'сухая',
  'сухий',
  'сухый',
  'сучня',
  'сучок',
  'сучье',
  'сушка',
  'сфера',
  'схема',
  'схима',
  'схрон',
  'сцаки',
  'сцена',
  'счеты',
  'съезд',
  'сыпец',
  'сырец',
  'сырок',
  'сырье',
  'сычуг',
  'сэнто',
  'сюжет',
  'сюита',
  'сюрко',
  'сявка',
  'сяжок',
  'сянци',
  'табак',
  'табес',
  'табия',
  'табла',
  'табло',
  'табор',
  'табун',
  'таваф',
  'тавот',
  'тавро',
  'таган',
  'тазер',
  'тазик',
  'таити',
  'тайга',
  'тайит',
  'тайка',
  'тайна',
  'тайтл',
  'тайфа',
  'такса',
  'такси',
  'такыр',
  'талер',
  'талес',
  'талец',
  'талик',
  'талит',
  'талия',
  'талон',
  'талус',
  'тальк',
  'талья',
  'тамга',
  'тангл',
  'танго',
  'танец',
  'танин',
  'танка',
  'таолу',
  'тапас',
  'тапка',
  'тапок',
  'таран',
  'тараф',
  'тариф',
  'таска',
  'татра',
  'тафия',
  'тафта',
  'тафья',
  'тахта',
  'тачка',
  'ташка',
  'таэль',
  'тверк',
  'твикс',
  'твист',
  'твитт',
  'театр',
  'тезис',
  'теизм',
  'тейбл',
  'текст',
  'телеш',
  'телик',
  'телия',
  'теллс',
  'тембр',
  'темка',
  'тенге',
  'тенек',
  'тения',
  'тенор',
  'тепло',
  'терат',
  'терем',
  'терка',
  'терма',
  'термы',
  'терно',
  'терра',
  'тесак',
  'тесис',
  'теска',
  'тесла',
  'тесло',
  'теста',
  'тесто',
  'тетри',
  'тетум',
  'тефра',
  'техан',
  'техно',
  'течка',
  'тешка',
  'тиара',
  'тизер',
  'тийин',
  'тикер',
  'тилла',
  'тилль',
  'тильт',
  'тимин',
  'тимол',
  'тимус',
  'типаж',
  'типец',
  'типик',
  'типле',
  'типун',
  'тираж',
  'тирит',
  'тиски',
  'титан',
  'титин',
  'титла',
  'титло',
  'титул',
  'тифон',
  'тихит',
  'ткань',
  'тмеза',
  'тоала',
  'товар',
  'тодес',
  'тозик',
  'токай',
  'токен',
  'толар',
  'толос',
  'толпа',
  'толща',
  'толщь',
  'томан',
  'томат',
  'томик',
  'томос',
  'тонар',
  'тондо',
  'тонер',
  'тоний',
  'тоник',
  'тония',
  'тонна',
  'тонус',
  'тонфа',
  'топаз',
  'топик',
  'топия',
  'топка',
  'топор',
  'топос',
  'топот',
  'торба',
  'торги',
  'торец',
  'тории',
  'торий',
  'торит',
  'торон',
  'торос',
  'торус',
  'тоска',
  'тотал',
  'тотем',
  'тофус',
  'точка',
  'тощак',
  'трабл',
  'трава',
  'трайб',
  'трайк',
  'трайт',
  'тракт',
  'трама',
  'трамп',
  'транс',
  'транш',
  'трапп',
  'трасс',
  'траст',
  'трата',
  'траур',
  'треба',
  'трейд',
  'трейл',
  'трель',
  'трема',
  'тренд',
  'тренч',
  'треск',
  'трест',
  'треть',
  'треух',
  'трефа',
  'трефы',
  'треха',
  'триал',
  'триас',
  'триба',
  'триер',
  'тризм',
  'трико',
  'триод',
  'трион',
  'трипс',
  'тромб',
  'тромп',
  'трона',
  'тропа',
  'трояк',
  'троян',
  'труба',
  'трусы',
  'труха',
  'трюмо',
  'тсуга',
  'тубик',
  'тубус',
  'тугра',
  'тужба',
  'тузик',
  'туйон',
  'тулий',
  'тулит',
  'тулук',
  'тулуп',
  'тульч',
  'тулья',
  'тумак',
  'туман',
  'тумба',
  'тумор',
  'тунит',
  'тупей',
  'тупец',
  'тупик',
  'тупье',
  'турак',
  'турка',
  'турма',
  'турне',
  'тусеж',
  'тутор',
  'тутти',
  'туфля',
  'туфта',
  'тучка',
  'тушка',
  'тхина',
  'тшува',
  'тщета',
  'тыйын',
  'тыква',
  'тынок',
  'тырка',
  'тырло',
  'тырса',
  'тычок',
  'тэнит',
  'тэнсю',
  'тэшка',
  'тюбаж',
  'тюбик',
  'тюнер',
  'тюник',
  'тюрик',
  'тюрюк',
  'тютюн',
  'тюфяк',
  'тючок',
  'тябло',
  'тягло',
  'тягун',
  'тяжба',
  'тяжка',
  'тяпка',
  'уазик',
  'уброд',
  'убрус',
  'убыль',
  'увеит',
  'увраж',
  'угода',
  'уголь',
  'угорь',
  'угрев',
  'удаль',
  'удача',
  'удерж',
  'удила',
  'ужака',
  'ужище',
  'узвар',
  'уздцы',
  'узерк',
  'узина',
  'узкая',
  'узура',
  'уклад',
  'уклон',
  'укроп',
  'укрюк',
  'уксус',
  'улика',
  'улица',
  'улово',
  'умами',
  'умбит',
  'умбон',
  'умбра',
  'умище',
  'умолк',
  'умора',
  'унами',
  'унбан',
  'униат',
  'унция',
  'уныль',
  'упаги',
  'упрек',
  'ураза',
  'уреид',
  'урема',
  'урема',
  'урина',
  'урман',
  'урсон',
  'уртит',
  'уруси',
  'усики',
  'усище',
  'успех',
  'устав',
  'устой',
  'уступ',
  'устье',
  'утеря',
  'утеха',
  'утиль',
  'ухват',
  'ухожь',
  'учага',
  'учеба',
  'учхоз',
  'ушица',
  'ушкуй',
  'ущерб',
  'фабза',
  'фабра',
  'фавор',
  'фавус',
  'фагот',
  'фаджр',
  'фадом',
  'фазис',
  'файер',
  'факап',
  'факел',
  'фалак',
  'фалда',
  'фальц',
  'фамбл',
  'фанза',
  'фанки',
  'фанта',
  'фарад',
  'фарма',
  'фарси',
  'фарца',
  'фасад',
  'фасет',
  'фаска',
  'фасон',
  'фасты',
  'фатом',
  'фатта',
  'фатум',
  'фауна',
  'фацет',
  'фация',
  'фашня',
  'фаянс',
  'феатр',
  'фекал',
  'фенил',
  'фенол',
  'ферзь',
  'ферма',
  'ферми',
  'феска',
  'фетва',
  'фетиш',
  'фиакр',
  'фиала',
  'фибра',
  'фигня',
  'фидер',
  'фиджи',
  'фижма',
  'физия',
  'физра',
  'фикса',
  'фикус',
  'фикшн',
  'филей',
  'филло',
  'филум',
  'фильм',
  'фильц',
  'фимоз',
  'финал',
  'финбо',
  'финик',
  'финиш',
  'финка',
  'финна',
  'фиорд',
  'фирма',
  'фитин',
  'фитол',
  'фичер',
  'фишай',
  'фишка',
  'флаер',
  'фланг',
  'фланк',
  'флейм',
  'флейт',
  'флейц',
  'флешь',
  'флиак',
  'флинт',
  'флирт',
  'флоат',
  'флокс',
  'флопс',
  'флора',
  'флосс',
  'флуер',
  'флюид',
  'фляга',
  'фобия',
  'фокус',
  'фолио',
  'фолия',
  'фолос',
  'фомит',
  'фомич',
  'фомка',
  'фондю',
  'фонон',
  'форез',
  'форма',
  'форте',
  'форум',
  'фоска',
  'фотик',
  'фотка',
  'фотон',
  'фофан',
  'фраза',
  'франк',
  'фрахт',
  'фреза',
  'фрейм',
  'френд',
  'френч',
  'фреон',
  'фреск',
  'фронт',
  'фрукт',
  'фрунт',
  'фрязь',
  'фугас',
  'фужер',
  'фузея',
  'фузия',
  'фузор',
  'фукус',
  'фуляр',
  'фураж',
  'фуран',
  'фурил',
  'фурма',
  'фурол',
  'фурор',
  'фурри',
  'фусха',
  'футаж',
  'футер',
  'футон',
  'футор',
  'фуфло',
  'фуэте',
  'фуяра',
  'фырок',
  'фьорд',
  'фьюжн',
  'фюзен',
  'фюмаж',
  'фюсис',
  'хабар',
  'хавка',
  'хадак',
  'хадис',
  'хайку',
  'хайло',
  'хакит',
  'хакка',
  'халал',
  'халат',
  'халва',
  'хамеж',
  'хамон',
  'хамье',
  'ханат',
  'ханжа',
  'ханка',
  'хаома',
  'хапка',
  'хапун',
  'харам',
  'харио',
  'харчи',
  'харчо',
  'хаста',
  'хатка',
  'хауса',
  'хашар',
  'хвала',
  'хворь',
  'хвост',
  'хевея',
  'хедер',
  'хейит',
  'хелат',
  'хемоз',
  'хендж',
  'херем',
  'херес',
  'херик',
  'херня',
  'хиазм',
  'хилез',
  'хилус',
  'химия',
  'химка',
  'химус',
  'хинди',
  'хинин',
  'хинон',
  'хипеж',
  'хиппи',
  'хитин',
  'хитон',
  'хлупь',
  'хлыст',
  'хлюст',
  'хлябь',
  'хмарь',
  'хмель',
  'хмурь',
  'хоана',
  'хобби',
  'хобот',
  'ходка',
  'ходок',
  'ходун',
  'хозар',
  'хокку',
  'холим',
  'холин',
  'холка',
  'холод',
  'холон',
  'холст',
  'хольд',
  'хомут',
  'хомяк',
  'хонда',
  'хорал',
  'хорар',
  'хорда',
  'хорей',
  'хорея',
  'хорос',
  'хорус',
  'хоста',
  'хохма',
  'хохол',
  'хохот',
  'хошен',
  'хошун',
  'хрень',
  'хруст',
  'хряпа',
  'хряск',
  'хряст',
  'хтонь',
  'хуета',
  'хуило',
  'хуина',
  'хуище',
  'хуйло',
  'хуйня',
  'хукоу',
  'хумус',
  'хумчи',
  'хунта',
  'хурал',
  'хурма',
  'хурул',
  'хутба',
  'хутор',
  'хуцпа',
  'хуяка',
  'хуяра',
  'хычин',
  'хэток',
  'хюгге',
  'хянга',
  'цабан',
  'цанга',
  'цапка',
  'цапфа',
  'царга',
  'цацка',
  'цвель',
  'цветь',
  'цевка',
  'цевье',
  'цедра',
  'цезий',
  'целик',
  'целка',
  'целла',
  'целое',
  'целом',
  'ценоз',
  'центр',
  'ценур',
  'цепка',
  'цепня',
  'церий',
  'церит',
  'церка',
  'цетан',
  'цехин',
  'цешка',
  'цзинь',
  'цибет',
  'цибик',
  'цигун',
  'цизит',
  'цикля',
  'цимен',
  'цимес',
  'цимол',
  'цимус',
  'цинга',
  'цинка',
  'ципао',
  'цирен',
  'цирлы',
  'циста',
  'цитоз',
  'цитра',
  'цифра',
  'цицес',
  'цицит',
  'цокот',
  'цукат',
  'цыбик',
  'цынга',
  'цыпки',
  'чабер',
  'чабер',
  'чадра',
  'чаище',
  'чайка',
  'чакан',
  'чакра',
  'чалка',
  'чалма',
  'чаман',
  'чаоит',
  'чапан',
  'чарас',
  'чарка',
  'чарок',
  'чарым',
  'часик',
  'часок',
  'часть',
  'чатик',
  'чатни',
  'чафан',
  'чашка',
  'чебот',
  'чеджу',
  'чекан',
  'чекап',
  'челка',
  'челси',
  'чендж',
  'ченит',
  'чепан',
  'чепец',
  'чераз',
  'черва',
  'черви',
  'червы',
  'червь',
  'черед',
  'черен',
  'череп',
  'чернь',
  'черта',
  'ческа',
  'честь',
  'четки',
  'чехит',
  'чехол',
  'чечка',
  'чешка',
  'чешуя',
  'чижик',
  'чилим',
  'чимчи',
  'чинар',
  'чинка',
  'чинук',
  'чипок',
  'чирей',
  'чирик',
  'чиряк',
  'число',
  'читка',
  'читта',
  'чифир',
  'чичер',
  'чобот',
  'чован',
  'чокер',
  'чокой',
  'чопик',
  'чопин',
  'чрево',
  'чреда',
  'чтиво',
  'чуана',
  'чубик',
  'чубук',
  'чувал',
  'чувяк',
  'чугун',
  'чужое',
  'чуйка',
  'чулан',
  'чулок',
  'чумка',
  'чурак',
  'чурек',
  'чурка',
  'чутье',
  'чучхе',
  'чушка',
  'чушня',
  'чушок',
  'шабаш',
  'шабер',
  'шабли',
  'шабот',
  'шабур',
  'шавру',
  'шавыр',
  'шадуф',
  'шажок',
  'шайба',
  'шайка',
  'шакти',
  'шалаш',
  'шалот',
  'шамбо',
  'шамир',
  'шамма',
  'шамот',
  'шамуа',
  'шанец',
  'шанкр',
  'шанти',
  'шапка',
  'шарик',
  'шасла',
  'шассе',
  'шасси',
  'шатер',
  'шатия',
  'шаттл',
  'шатун',
  'шатуш',
  'шауйя',
  'шахец',
  'шахна',
  'шахта',
  'шашка',
  'шашки',
  'шашни',
  'шваль',
  'шванк',
  'швара',
  'шварт',
  'швепс',
  'шверт',
  'шевер',
  'шевро',
  'шевяк',
  'шейка',
  'шейма',
  'шелеп',
  'шелом',
  'шелон',
  'шельф',
  'шепот',
  'шерри',
  'шиацу',
  'шибер',
  'шиизм',
  'шилье',
  'шимми',
  'шинил',
  'шинка',
  'шинок',
  'шипик',
  'шипок',
  'ширма',
  'шитво',
  'шитик',
  'шитье',
  'шифер',
  'шифон',
  'шихан',
  'шихта',
  'шишак',
  'шишка',
  'шкала',
  'шкант',
  'шквал',
  'шкерт',
  'шкеры',
  'шкода',
  'школа',
  'шкура',
  'шланг',
  'шлейф',
  'шлихт',
  'шлица',
  'шляпа',
  'шмаль',
  'шмара',
  'шмура',
  'шмяка',
  'шнапс',
  'шнека',
  'шнифт',
  'шнява',
  'шняга',
  'шобла',
  'шодди',
  'шокер',
  'шопер',
  'шопот',
  'шорня',
  'шорох',
  'шорты',
  'шоссе',
  'шоссы',
  'шофар',
  'шпага',
  'шпала',
  'шпиль',
  'шпона',
  'шпора',
  'шприц',
  'шпуля',
  'шпунт',
  'шренц',
  'шримс',
  'шрифт',
  'штамб',
  'штамм',
  'штамп',
  'штанц',
  'штаны',
  'штейн',
  'штерт',
  'штиль',
  'штифт',
  'штора',
  'шторм',
  'штраф',
  'штрек',
  'штрих',
  'штука',
  'штурм',
  'штырь',
  'шубат',
  'шубер',
  'шубка',
  'шугай',
  'шуйца',
  'шулюм',
  'шумок',
  'шурпа',
  'шуруп',
  'шутер',
  'шутка',
  'шухер',
  'шушун',
  'шхера',
  'шхуна',
  'шютте',
  'щебет',
  'щекот',
  'щелка',
  'щелок',
  'щенка',
  'щепка',
  'щетка',
  'щетки',
  'щечка',
  'щипец',
  'щипка',
  'щипок',
  'щипцы',
  'щиток',
  'щишки',
  'щупик',
  'ыгрек',
  'эвеит',
  'эвент',
  'эгида',
  'эглет',
  'эгрет',
  'эдема',
  'эдикт',
  'эйдос',
  'экзит',
  'экзон',
  'экзот',
  'эккер',
  'эклер',
  'экран',
  'эксод',
  'экспа',
  'экшен',
  'экшон',
  'элиит',
  'элита',
  'элюат',
  'эмаль',
  'эмбол',
  'эммер',
  'эндсы',
  'энзим',
  'энион',
  'энтер',
  'эозин',
  'эолит',
  'эоцен',
  'эпика',
  'эпонж',
  'эпоха',
  'эпюра',
  'эрбий',
  'эрдит',
  'эрзац',
  'эрика',
  'эркер',
  'эскер',
  'эскиз',
  'эспри',
  'эссив',
  'эстер',
  'этвеш',
  'этика',
  'этнос',
  'этрап',
  'этрол',
  'эшарп',
  'эшель',
  'юдоль',
  'юзинь',
  'юката',
  'юкола',
  'юмизм',
  'юнгит',
  'юнифа',
  'юрага',
  'юризм',
  'юриит',
  'юрфак',
  'юссив',
  'юферс',
  'юэчжи',
  'ябеда',
  'ягель',
  'ягиит',
  'ягода',
  'ягоит',
  'ягурт',
  'яенин',
  'язина',
  'яичко',
  'яишня',
  'якорь',
  'якуба',
  'ялбот',
  'ямега',
  'ямина',
  'ямища',
  'ямпец',
  'янтра',
  'ярица',
  'ярлит',
  'ярлык',
  'яруга',
  'ясень',
  'ясмин',
  'яспис',
  'ястак',
  'яство',
  'ястык',
  'ятовь',
  'яхонт',
  'ячник',
  'аудио',
  'нарды',
  'нарыв',
  'бигос',
  'хамон',
  'умора',
  'донат',
  'оммаж',
  'аббат',
  'аргон',
  'боран',
  'клоны',
  'кегля',
  'камео',
  'аниме',
  'дания',
  'кегли',
  'рокер',
  'хуйня',
  'крупа',
  'карго',
  'абрек',
  'абхаз',
  'авгур',
  'автор',
  'агама',
  'агнат',
  'агнец',
  'агнцы',
  'агути',
  'адепт',
  'адити',
  'азиат',
  'айсор',
  'актер',
  'акула',
  'алеут',
  'алкаш',
  'аллах',
  'алонж',
  'альва',
  'амбал',
  'амеба',
  'ангел',
  'аншеф',
  'аргус',
  'арден',
  'ариец',
  'архар',
  'аскер',
  'аскет',
  'атлет',
  'афина',
  'ахеец',
  'ацтек',
  'бабай',
  'бабье',
  'багры',
  'бакши',
  'бальи',
  'банту',
  'барич',
  'барон',
  'батек',
  'батыр',
  'бахус',
  'бахши',
  'бачки',
  'бекас',
  'белек',
  'белец',
  'белое',
  'белый',
  'белая',
  'белье',
  'беляк',
  'бердо',
  'берце',
  'бивни',
  'бигус',
  'бизон',
  'бионт',
  'бирюк',
  'бирюч',
  'битый',
  'битье',
  'битюг',
  'блоха',
  'блюмс',
  'блядь',
  'бобер',
  'бобик',
  'богач',
  'бодун',
  'бойцы',
  'божок',
  'бонза',
  'бонна',
  'борцы',
  'боров',
  'босяк',
  'бочар',
  'бояре',
  'браво',
  'бритт',
  'бугры',
  'будни',
  'бузун',
  'букан',
  'бурят',
  'бутуз',
  'былье',
  'бытье',
  'бэнди',
  'валах',
  'валек',
  'валер',
  'валет',
  'валух',
  'варан',
  'варяг',
  'вахня',
  'вдова',
  'ведун',
  'везир',
  'вейка',
  'велит',
  'вельс',
  'венгр',
  'венед',
  'венет',
  'венцы',
  'вепрь',
  'ветры',
  'вещее',
  'вещун',
  'взлет',
  'взмет',
  'вивер',
  'вития',
  'витье',
  'вихры',
  'вишну',
  'вобла',
  'вогул',
  'вожак',
  'вождь',
  'волох',
  'волхв',
  'ворог',
  'ворон',
  'ворье',
  'вотяк',
  'вошка',
  'вояка',
  'враль',
  'вруша',
  'вывес',
  'выдра',
  'вызол',
  'вымах',
  'вытье',
  'вычет',
  'вышаг',
  'вятич',
  'вятка',
  'гадес',
  'ганец',
  'ганцы',
  'ганза',
  'ганка',
  'гаучо',
  'герлс',
  'герой',
  'гетит',
  'гиады',
  'гидра',
  'гиена',
  'гиляк',
  'гитан',
  'глень',
  'глипт',
  'глист',
  'голем',
  'голый',
  'голая',
  'голые',
  'гольд',
  'голье',
  'голяк',
  'гонец',
  'гонцы',
  'горал',
  'горцы',
  'горюн',
  'гость',
  'гранд',
  'греза',
  'губан',
  'гулаг',
  'гуран',
  'гурия',
  'гусар',
  'гусек',
  'гусит',
  'гуцул',
  'гэпэу',
  'гюрза',
  'дайна',
  'дайра',
  'дебил',
  'дебрь',
  'девка',
  'дегти',
  'дедка',
  'дедок',
  'дежка',
  'деист',
  'дележ',
  'делец',
  'денди',
  'денек',
  'дерен',
  'детка',
  'джинн',
  'диана',
  'дикий',
  'дикая',
  'дикие',
  'дилер',
  'донья',
  'докер',
  'домек',
  'домер',
  'донец',
  'донцы',
  'донна',
  'донор',
  'досуг',
  'дофин',
  'дрема',
  'дрозд',
  'дрофа',
  'друид',
  'дубцы',
  'дубье',
  'дудак',
  'дулеб',
  'думец',
  'думцы',
  'дунец',
  'дунцы',
  'дурни',
  'дутыш',
  'дутье',
  'дщерь',
  'дылда',
  'дядек',
  'дядья',
  'дятел',
  'дятлы',
  'евнух',
  'еврей',
  'егерь',
  'егоза',
  'ежиха',
  'ежата',
  'ездок',
  'емеля',
  'есаул',
  'ессей',
  'ехида',
  'желна',
  'желоб',
  'жених',
  'женка',
  'жерех',
  'живец',
  'живцы',
  'живое',
  'живые',
  'живье',
  'жидок',
  'жилец',
  'жилое',
  'жилье',
  'жинка',
  'жираф',
  'жиряк',
  'житье',
  'жница',
  'жокей',
  'жрица',
  'жулан',
  'жулик',
  'жулье',
  'журка',
  'жучка',
  'завуч',
  'займы',
  'заика',
  'зайка',
  'закуп',
  'залет',
  'замет',
  'затек',
  'затес',
  'затяг',
  'зачес',
  'зачет',
  'зайцы',
  'зверь',
  'земец',
  'земцы',
  'злюка',
  'зомби',
  'зубья',
  'зубцы',
  'зудни',
  'зятек',
  'зятик',
  'иблис',
  'иваси',
  'игрец',
  'игрок',
  'игрун',
  'идиот',
  'иерей',
  'избач',
  'изгой',
  'изида',
  'излет',
  'изюбр',
  'иктус',
  'илька',
  'имена',
  'ингуш',
  'индра',
  'индус',
  'индюк',
  'иомен',
  'иомуд',
  'иомут',
  'иония',
  'ирбис',
  'ирида',
  'исида',
  'истец',
  'истцы',
  'итээр',
  'иудей',
  'ифрит',
  'йомен',
  'кааба',
  'кабан',
  'кабил',
  'каган',
  'кадет',
  'кадиш',
  'казак',
  'казах',
  'кайра',
  'калан',
  'калба',
  'калиф',
  'камни',
  'камса',
  'канюк',
  'кариб',
  'касик',
  'кацап',
  'кацик',
  'кашли',
  'кашуб',
  'кащей',
  'кенар',
  'кидас',
  'кимвр',
  'киник',
  'кипка',
  'клект',
  'клерк',
  'клест',
  'клефт',
  'клоун',
  'клуша',
  'клюфт',
  'кляча',
  'кникс',
  'князь',
  'коала',
  'коата',
  'коблы',
  'кобра',
  'ковач',
  'ковер',
  'ковры',
  'когти',
  'кожье',
  'козел',
  'койот',
  'кокет',
  'колья',
  'колли',
  'комья',
  'комар',
  'комли',
  'комик',
  'конек',
  'концы',
  'конюх',
  'копач',
  'копер',
  'копры',
  'копун',
  'копье',
  'коран',
  'корни',
  'корцы',
  'кореш',
  'корчь',
  'корье',
  'коряк',
  'косач',
  'косая',
  'косые',
  'косец',
  'косцы',
  'косма',
  'косой',
  'косье',
  'котел',
  'котик',
  'коток',
  'коцит',
  'кочни',
  'кочет',
  'кощей',
  'краля',
  'креол',
  'кроат',
  'кудлы',
  'кузен',
  'кукша',
  'кулаж',
  'кулан',
  'кулек',
  'кулик',
  'кумжа',
  'кумык',
  'кунак',
  'куньи',
  'купец',
  'купцы',
  'курва',
  'курец',
  'курцы',
  'кутеж',
  'кутум',
  'кучер',
  'кхмер',
  'лабет',
  'лазка',
  'лакей',
  'лакец',
  'лакцы',
  'ламут',
  'ланка',
  'лапти',
  'ларго',
  'ларек',
  'ларцы',
  'латин',
  'латыш',
  'лачка',
  'левит',
  'левша',
  'левый',
  'легат',
  'легаш',
  'лежни',
  'лежка',
  'лекаж',
  'лемеш',
  'лемур',
  'летун',
  'лешак',
  'леший',
  'лещик',
  'ливни',
  'ливка',
  'лидер',
  'ликер',
  'линек',
  'липцы',
  'лирик',
  'лиска',
  'литье',
  'лихач',
  'лобан',
  'ловец',
  'ловцы',
  'логик',
  'локти',
  'ломти',
  'лошак',
  'лубья',
  'лысун',
  'лысый',
  'любви',
  'любое',
  'любой',
  'любая',
  'любые',
  'люрик',
  'лютич',
  'лягва',
  'лярва',
  'ляшка',
  'магик',
  'магот',
  'мадам',
  'мазло',
  'мазур',
  'майер',
  'майор',
  'малек',
  'малец',
  'малое',
  'малый',
  'малье',
  'маляр',
  'мамай',
  'маман',
  'мамка',
  'манси',
  'манул',
  'маори',
  'марал',
  'маран',
  'масаи',
  'масон',
  'маток',
  'махан',
  'мачок',
  'медея',
  'медик',
  'мекка',
  'мелис',
  'мерея',
  'месье',
  'метек',
  'метис',
  'мигач',
  'милое',
  'милый',
  'милая',
  'милые',
  'мильт',
  'мимик',
  'минер',
  'минос',
  'мираб',
  'мирза',
  'мирон',
  'мишка',
  'мишук',
  'могол',
  'мойва',
  'мойра',
  'мокой',
  'молох',
  'монах',
  'мослы',
  'мосье',
  'мохна',
  'мудак',
  'мужья',
  'мужик',
  'мулат',
  'мулек',
  'мулла',
  'мумие',
  'мураш',
  'мурда',
  'мурза',
  'мурин',
  'мурка',
  'мушар',
  'мымра',
  'мытье',
  'мюрид',
  'мямля',
  'мятье',
  'набоб',
  'наймы',
  'нажиг',
  'назем',
  'накол',
  'накра',
  'налет',
  'налим',
  'намек',
  'намет',
  'нанай',
  'нанду',
  'наруч',
  'натек',
  'натес',
  'нахал',
  'начес',
  'начет',
  'наяда',
  'невер',
  'негус',
  'немец',
  'немцы',
  'немка',
  'немой',
  'немая',
  'немые',
  'ненец',
  'ненцы',
  'ненка',
  'нерка',
  'нерпа',
  'несун',
  'нетяг',
  'нивух',
  'никса',
  'никто',
  'нилот',
  'нимфа',
  'ничто',
  'нищий',
  'нищая',
  'нищие',
  'новик',
  'ногай',
  'ногти',
  'нойон',
  'номад',
  'носач',
  'ночва',
  'нудга',
  'нукер',
  'нырец',
  'нырцы',
  'нытик',
  'нытье',
  'обдел',
  'облет',
  'обмет',
  'ободь',
  'обчет',
  'объем',
  'овист',
  'овощь',
  'овчар',
  'одежа',
  'ойрот',
  'окапи',
  'окоем',
  'окунь',
  'олень',
  'омуль',
  'опята',
  'оптик',
  'орион',
  'орлан',
  'орлик',
  'орфей',
  'осетр',
  'ослик',
  'осляк',
  'осман',
  'особа',
  'особь',
  'осоед',
  'осташ',
  'остяк',
  'отлет',
  'отмол',
  'отрок',
  'оттек',
  'отчет',
  'отчим',
  'отъем',
  'офеня',
  'оффис',
  'очник',
  'ошкуй',
  'падла',
  'палач',
  'палия',
  'панер',
  'паныч',
  'папах',
  'папеж',
  'парни',
  'пария',
  'парня',
  'пасюк',
  'патан',
  'патер',
  'патуа',
  'пацан',
  'паяло',
  'певни',
  'певец',
  'певцы',
  'певун',
  'пегас',
  'пенге',
  'пенек',
  'пеплы',
  'перцы',
  'перка',
  'перло',
  'перья',
  'песцы',
  'песик',
  'петел',
  'петух',
  'пешец',
  'пешцы',
  'пеший',
  'пешая',
  'пешие',
  'пижон',
  'пикша',
  'пилат',
  'пимок',
  'пират',
  'писец',
  'писцы',
  'писун',
  'питок',
  'питух',
  'питье',
  'пифия',
  'плаке',
  'плесо',
  'плохо',
  'повар',
  'позем',
  'позер',
  'позык',
  'полет',
  'полип',
  'полир',
  'поляк',
  'поляш',
  'помет',
  'помор',
  'попик',
  'пороз',
  'порос',
  'послы',
  'потек',
  'похул',
  'почет',
  'приап',
  'прием',
  'принц',
  'приор',
  'проем',
  'профи',
  'прусс',
  'пряха',
  'псарь',
  'псица',
  'птаха',
  'птица',
  'пузан',
  'пузач',
  'пунцы',
  'пусто',
  'путцы',
  'пучка',
  'пушта',
  'пчела',
  'пьеро',
  'пэтэу',
  'пятый',
  'пятая',
  'пятые',
  'раджа',
  'ракун',
  'ракша',
  'ранцы',
  'ратай',
  'рачок',
  'регия',
  'резцы',
  'релит',
  'ремез',
  'ремни',
  'репьи',
  'репье',
  'ржица',
  'рикша',
  'рипус',
  'ритор',
  'ришта',
  'робот',
  'ровня',
  'рогач',
  'родич',
  'рожак',
  'рожны',
  'ронжа',
  'рубцы',
  'ружье',
  'румын',
  'рунец',
  'рунцы',
  'русак',
  'ручьи',
  'рыбак',
  'рыбец',
  'рыбцы',
  'рыбка',
  'рыжак',
  'рыжей',
  'рысак',
  'рытье',
  'рябец',
  'рябцы',
  'рябой',
  'рябая',
  'рябые',
  'рябок',
  'сабей',
  'сабин',
  'савка',
  'саган',
  'сагиб',
  'саджа',
  'сазан',
  'сайга',
  'сайда',
  'сайра',
  'салар',
  'салеп',
  'салка',
  'самец',
  'самцы',
  'самка',
  'сапер',
  'сарыч',
  'сатир',
  'сахиб',
  'сваха',
  'свояк',
  'севак',
  'севец',
  'севцы',
  'седок',
  'сезни',
  'сейид',
  'семга',
  'семит',
  'серна',
  'серое',
  'серый',
  'серая',
  'серые',
  'сеунч',
  'сивка',
  'сивко',
  'сивуч',
  'сивый',
  'сивая',
  'сивые',
  'сидни',
  'сижок',
  'сизиф',
  'сизяк',
  'силач',
  'силен',
  'сильф',
  'синец',
  'синцы',
  'синяя',
  'синие',
  'сипай',
  'сирен',
  'сирин',
  'сирый',
  'сирая',
  'сирые',
  'ситцы',
  'скепт',
  'скунс',
  'слуга',
  'слуда',
  'слука',
  'смерд',
  'сноха',
  'совка',
  'сойка',
  'сойот',
  'сомик',
  'сомье',
  'сопун',
  'сосед',
  'сосцы',
  'сосун',
  'сотый',
  'сохач',
  'сочни',
  'сочок',
  'срост',
  'стень',
  'стерх',
  'стикс',
  'стоик',
  'страж',
  'стриж',
  'ступь',
  'стыдь',
  'судак',
  'судия',
  'судья',
  'сучья',
  'супцы',
  'сурок',
  'сучка',
  'сучье',
  'сущее',
  'сущик',
  'сцинк',
  'сынок',
  'сыпцы',
  'сырцы',
  'сырца',
  'сырье',
  'сытый',
  'сытая',
  'сытые',
  'сыщик',
  'сэшэа',
  'сябер',
  'тайцы',
  'таймс',
  'такое',
  'талан',
  'талиб',
  'талыш',
  'тамил',
  'танцы',
  'танок',
  'тапер',
  'тапир',
  'тарын',
  'татка',
  'тауэр',
  'тацет',
  'тварь',
  'тезей',
  'тезка',
  'теист',
  'телек',
  'телец',
  'телка',
  'телок',
  'тенек',
  'терец',
  'терцы',
  'терка',
  'тесей',
  'теска',
  'тесть',
  'тетка',
  'теург',
  'тешка',
  'тигли',
  'тинка',
  'типцы',
  'тиран',
  'тифус',
  'тлень',
  'тойон',
  'толай',
  'томми',
  'торцы',
  'тоуэр',
  'тохар',
  'туаль',
  'тугай',
  'тугун',
  'тукан',
  'тулес',
  'тулуз',
  'тулун',
  'туляк',
  'тунец',
  'тунцы',
  'тупцы',
  'тупыш',
  'турач',
  'турок',
  'тюрбе',
  'тюрок',
  'тягач',
  'углич',
  'удило',
  'удэге',
  'удэхе',
  'ужата',
  'узанс',
  'узбек',
  'уздца',
  'узник',
  'уйгур',
  'уклея',
  'укрут',
  'улыба',
  'ульта',
  'умник',
  'умный',
  'умная',
  'умные',
  'унион',
  'унтер',
  'упрек',
  'упырь',
  'урарт',
  'усина',
  'ускок',
  'утица',
  'ухарь',
  'ухряб',
  'учеба',
  'ушник',
  'фазан',
  'факир',
  'фанат',
  'фатюй',
  'фелло',
  'фелон',
  'фений',
  'фетюк',
  'фефер',
  'фигли',
  'физик',
  'филер',
  'филин',
  'филон',
  'фляер',
  'фраер',
  'франт',
  'фурия',
  'фюрер',
  'хазар',
  'хакас',
  'хакер',
  'халда',
  'халиф',
  'хамит',
  'хамка',
  'хамло',
  'хамса',
  'хамье',
  'ханты',
  'ханша',
  'харон',
  'хасид',
  'хашиш',
  'хедив',
  'хилый',
  'хилая',
  'хилые',
  'хиляк',
  'химик',
  'хирот',
  'хлест',
  'хмара',
  'хмырь',
  'ходни',
  'ходжа',
  'ходик',
  'холоп',
  'холуй',
  'хохлы',
  'худое',
  'хунну',
  'хурда',
  'цадик',
  'цапля',
  'цапун',
  'царек',
  'цветы',
  'цевье',
  'цепни',
  'цефей',
  'цинаш',
  'циник',
  'цирик',
  'цуцик',
  'цыган',
  'цыпка',
  'цэрэу',
  'чабан',
  'чабры',
  'чалый',
  'чалая',
  'чалые',
  'чебак',
  'чебот',
  'челка',
  'чемер',
  'чепцы',
  'черед',
  'через',
  'ческа',
  'четка',
  'чехлы',
  'чечен',
  'чечет',
  'чибис',
  'чипсы',
  'чирьи',
  'чирок',
  'чирус',
  'чомга',
  'чомпи',
  'чтица',
  'чувак',
  'чуваш',
  'чудак',
  'чудик',
  'чужак',
  'чужая',
  'чужие',
  'чукча',
  'чумак',
  'чутье',
  'чухна',
  'шабат',
  'шабры',
  'шабля',
  'шавка',
  'шакал',
  'шалон',
  'шалун',
  'шаман',
  'шанцы',
  'шарап',
  'шатен',
  'шатер',
  'шатры',
  'шафер',
  'шеища',
  'шемая',
  'шепот',
  'шериф',
  'шизик',
  'шилья',
  'шинто',
  'шипун',
  'шитье',
  'шлюха',
  'шмель',
  'шмоль',
  'шняка',
  'шорец',
  'шорцы',
  'шорка',
  'шофер',
  'шпана',
  'шпень',
  'шпинь',
  'шпион',
  'шпрот',
  'шпынь',
  'штраб',
  'штыка',
  'шудра',
  'шулер',
  'шумер',
  'шурин',
  'шуряк',
  'щебни',
  'щегол',
  'щеглы',
  'щекот',
  'щелок',
  'щенок',
  'щепье',
  'щерба',
  'щетка',
  'щечка',
  'щитни',
  'щитик',
  'щупка',
  'щурка',
  'щурок',
  'щучка',
  'элеат',
  'эллин',
  'эпарх',
  'эрата',
  'эсдек',
  'эссен',
  'эстет',
  'эфиоп',
  'югрич',
  'югурт',
  'южане',
  'юзист',
  'юниор',
  'юница',
  'юнкер',
  'юнкор',
  'юннат',
  'юнона',
  'юноша',
  'юрист',
  'ягуар',
  'ямщик',
  'ярыга',
  'ясырь',
  'ятвяг',
  'ласты',
  'блять',
  'решала',
  'права',
  'стихи',
  'шесть',
  'актер',
  'тетка',
  'шофер',
  'малыш',
  'овощи',
  'козел',
  'будда',
  'барин',
  'мюсли',
  'тапки',
  'сливы',
  'пидор',
  'шутер',
  'хорек',
  'ламер',
  'пахан',
  'ковен',
  'русич',
  'щелка',
  'веган',
  'детки',
  'дудец',
  'носки',
]
