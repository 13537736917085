export const WORDS = [
  'время',
  'жизнь',
  'слово',
  'место',
  'дверь',
  'земля',
  'конец',
  'голос',
  'город',
  'война',
  'часть',
  'книга',
  'улица',
  'вечер',
  'народ',
  'плечо',
  'палец',
  'мысль',
  'стена',
  'право',
  'месяц',
  'спина',
  'образ',
  'школа',
  'кровь',
  'берег',
  'семья',
  'ответ',
  'смысл',
  'форма',
  'грудь',
  'немец',
  'огонь',
  'армия',
  'гость',
  'ветер',
  'закон',
  'черта',
  'волос',
  'номер',
  'глава',
  'страх',
  'герой',
  'связь',
  'класс',
  'центр',
  'число',
  'рубль',
  'мужик',
  'автор',
  'наука',
  'кухня',
  'роман',
  'трава',
  'слеза',
  'запах',
  'точка',
  'толпа',
  'сапог',
  'дождь',
  'ворот',
  'сосед',
  'выход',
  'совет',
  'дурак',
  'поезд',
  'орган',
  'карта',
  'слава',
  'крыша',
  'повод',
  'птица',
  'водка',
  'живот',
  'фронт',
  'район',
  'мешок',
  'завод',
  'кулак',
  'сутки',
  'кусок',
  'волна',
  'пауза',
  'сотня',
  'майор',
  'театр',
  'хвост',
  'стихи',
  'вагон',
  'фильм',
  'труба',
  'масса',
  'линия',
  'лодка',
  'отдел',
  'весна',
  'успех',
  'сцена',
  'билет',
  'честь',
  'еврей',
  'фирма',
  'фраза',
  'замок',
  'рынок',
  'кость',
  'шутка',
  'зверь',
  'прием',
  'диван',
  'кошка',
  'покой',
  'отряд',
  'порог',
  'доска',
  'актер',
  'сумка',
  'мороз',
  'адрес',
  'горло',
  'текст',
  'тепло',
  'песок',
  'тайна',
  'рукав',
  'масло',
  'песня',
  'крыло',
  'осень',
  'туман',
  'шофер',
  'ручка',
  'поиск',
  'озеро',
  'музей',
  'пятно',
  'грязь',
  'добро',
  'капля',
  'экран',
  'среда',
  'штаны',
  'охота',
  'бровь',
  'ствол',
  'вывод',
  'штука',
  'холод',
  'полок',
  'режим',
  'забор',
  'шапка',
  'бабка',
  'песнь',
  'морда',
  'тоска',
  'строй',
  'буква',
  'пункт',
  'шепот',
  'ткань',
  'поток',
  'выбор',
  'палка',
  'взрыв',
  'вождь',
  'голод',
  'мечта',
  'дочка',
  'домик',
  'юноша',
  'океан',
  'тайга',
  'пачка',
  'сумма',
  'север',
  'шляпа',
  'топор',
  'брюки',
  'мотор',
  'крест',
  'ведро',
  'столб',
  'канал',
  'смена',
  'эпоха',
  'товар',
  'ветка',
  'скала',
  'обида',
  'запас',
  'печка',
  'орден',
  'склад',
  'цифра',
  'полет',
  'пожар',
  'радио',
  'халат',
  'шоссе',
  'лампа',
  'норма',
  'ложка',
  'башня',
  'ружье',
  'сфера',
  'судья',
  'отдых',
  'дрова',
  'тетка',
  'гений',
  'пакет',
  'танец',
  'князь',
  'бомба',
  'девка',
  'склон',
  'степь',
  'стиль',
  'метро',
  'рыбак',
  'взвод',
  'пламя',
  'марка',
  'папка',
  'разум',
  'белье',
  'благо',
  'ангел',
  'козел',
  'идиот',
  'моряк',
  'речка',
  'малыш',
  'отель',
  'ковер',
  'принц',
  'лидер',
  'спирт',
  'койка',
  'вызов',
  'такси',
  'агент',
  'шкура',
  'блеск',
  'лента',
  'бочок',
  'поход',
  'спора',
  'пушок',
  'искра',
  'сахар',
  'судно',
  'удача',
  'ножка',
  'драка',
  'отчет',
  'сарай',
  'мышца',
  'нация',
  'обмен',
  'лавка',
  'блюдо',
  'носок',
  'сосна',
  'олень',
  'акция',
  'жених',
  'рюмка',
  'свеча',
  'белок',
  'отказ',
  'сосуд',
  'наряд',
  'съезд',
  'рамка',
  'чашка',
  'почва',
  'нужда',
  'сюжет',
  'мышка',
  'майка',
  'схема',
  'монах',
  'ручей',
  'ветвь',
  'треск',
  'уголь',
  'плита',
  'слуга',
  'туфля',
  'череп',
  'пьеса',
  'барин',
  'петух',
  'барак',
  'архив',
  'базар',
  'рубеж',
  'почта',
  'струя',
  'налог',
  'маска',
  'атака',
  'барон',
  'кисть',
  'жилье',
  'спорт',
  'овраг',
  'визит',
  'табак',
  'гараж',
  'объем',
  'стадо',
  'заказ',
  'бокал',
  'арест',
  'хутор',
  'сынок',
  'кишка',
  'зерно',
  'идеал',
  'каюта',
  'жажда',
  'проза',
  'пасть',
  'ванна',
  'исход',
  'доход',
  'тварь',
  'намек',
  'знамя',
  'бедро',
  'фрукт',
  'петля',
  'шпион',
  'свист',
  'облик',
  'весло',
  'мусор',
  'племя',
  'суета',
  'вопль',
  'порыв',
  'обман',
  'нефть',
  'котел',
  'длина',
  'баран',
  'буфет',
  'серия',
  'купол',
  'копия',
  'башка',
  'фюрер',
  'ягода',
  'фокус',
  'тонна',
  'побег',
  'мадам',
  'смесь',
  'седло',
  'вздох',
  'закат',
  'пацан',
  'кузов',
  'казак',
  'сезон',
  'набор',
  'злоба',
  'салон',
  'грамм',
  'пилот',
  'брюхо',
  'комар',
  'испуг',
  'дырка',
  'бытие',
  'дымок',
  'повар',
  'касса',
  'графа',
  'крыса',
  'икона',
  'округ',
  'грань',
  'обрыв',
  'нитка',
  'банда',
  'плоть',
  'пепел',
  'хохот',
  'дрянь',
  'висок',
  'глина',
  'почка',
  'катер',
  'шарик',
  'виски',
  'сетка',
  'метод',
  'матка',
  'пяток',
  'заряд',
  'ребро',
  'мотив',
  'будка',
  'скука',
  'чудак',
  'устав',
  'погон',
  'битва',
  'жилец',
  'пульт',
  'груша',
  'гроза',
  'шахта',
  'магия',
  'тропа',
  'казнь',
  'перец',
  'дрожь',
  'выезд',
  'купец',
  'аллея',
  'собор',
  'миома',
  'учеба',
  'кукла',
  'позор',
  'весть',
  'слюна',
  'букет',
  'копье',
  'щенок',
  'обувь',
  'смола',
  'тупик',
  'молот',
  'рельс',
  'рыбка',
  'игрок',
  'шорох',
  'рывок',
  'сопка',
  'вдова',
  'порок',
  'певец',
  'налет',
  'парта',
  'груда',
  'проба',
  'треть',
  'юрист',
  'скрип',
  'сдача',
  'пульс',
  'миска',
  'рояль',
  'пение',
  'парус',
  'рация',
  'штора',
  'рвота',
  'ворон',
  'богач',
  'кепка',
  'спуск',
  'ларек',
  'палач',
  'парад',
  'диета',
  'посол',
  'гудок',
  'проем',
  'ущерб',
  'талия',
  'кучка',
  'физик',
  'сазан',
  'химия',
  'рычаг',
  'чулок',
  'упрек',
  'глубь',
  'факел',
  'опера',
  'пучок',
  'камин',
  'ссора',
  'месть',
  'пойма',
  'бубен',
  'лесок',
  'рубка',
  'логик',
  'залив',
  'звено',
  'глыба',
  'броня',
  'поляк',
  'каска',
  'тракт',
  'корка',
  'кабан',
  'бугор',
  'сеанс',
  'колея',
  'донос',
  'пытка',
  'лавра',
  'бычок',
  'вилок',
  'отход',
  'жулик',
  'манер',
  'штраф',
  'недра',
  'элита',
  'очерк',
  'лапка',
  'пирог',
  'хобот',
  'азарт',
  'скула',
  'пират',
  'филин',
  'паром',
  'лимон',
  'вышка',
  'посох',
  'комок',
  'шишка',
  'родня',
  'довод',
  'бухта',
  'опора',
  'спрос',
  'ласка',
  'вихрь',
  'прима',
  'венок',
  'киоск',
  'особа',
  'червь',
  'синяк',
  'культ',
  'штурм',
  'затея',
  'косяк',
  'бланк',
  'галка',
  'тачка',
  'сокол',
  'садик',
  'тесть',
  'кража',
  'балка',
  'салат',
  'драма',
  'вклад',
  'обыск',
  'конек',
  'вожак',
  'пресс',
  'демон',
  'мачта',
  'полюс',
  'корма',
  'багаж',
  'шхуна',
  'шашка',
  'клоун',
  'шайка',
  'тахта',
  'русло',
  'чайка',
  'химик',
  'сыщик',
  'озноб',
  'свита',
  'декан',
  'чутье',
  'напор',
  'венец',
  'дрозд',
  'мафия',
  'казна',
  'борец',
  'ножик',
  'батон',
  'влага',
  'обход',
  'шпага',
  'робот',
  'вахта',
  'арбуз',
  'ощупь',
  'сырье',
  'сатин',
  'тираж',
  'поэма',
  'сучок',
  'медик',
  'валун',
  'сквер',
  'пайка',
  'метла',
  'завал',
  'пласт',
  'сапер',
  'голец',
  'пчела',
  'страж',
  'шакал',
  'сабля',
  'сукно',
  'тезис',
  'лакей',
  'откос',
  'титул',
  'финал',
  'возня',
  'навес',
  'ангар',
  'кумир',
  'гряда',
  'крона',
  'подол',
  'хруст',
  'щепка',
  'турок',
  'леший',
  'ловля',
  'акула',
  'вздор',
  'отбой',
  'вилла',
  'дуэль',
  'нутро',
  'фланг',
  'веник',
  'пурга',
  'отбор',
  'вирус',
  'арена',
  'штамп',
  'щетка',
  'топот',
  'фасад',
  'въезд',
  'сталь',
  'шприц',
  'кабак',
  'жердь',
  'навык',
  'отсек',
  'сосок',
  'порох',
  'разок',
  'афиша',
  'фляга',
  'взлет',
  'питье',
  'холст',
  'лоток',
  'аборт',
  'банка',
  'чехол',
  'ворох',
  'кубок',
  'канат',
  'грипп',
  'почет',
  'газон',
  'кучер',
  'лопух',
  'тулуп',
  'купля',
  'стопа',
  'бетон',
  'тесто',
  'хабар',
  'сопля',
  'кочка',
  'рыжик',
  'шалаш',
  'балет',
  'денек',
  'аванс',
  'отзыв',
  'казах',
  'пинок',
  'носик',
  'мираж',
  'кивок',
  'залог',
  'улика',
  'старт',
  'понос',
  'горох',
  'устье',
  'цыган',
  'камыш',
  'обряд',
  'навоз',
  'обзор',
  'капот',
  'грунт',
  'губка',
  'паста',
  'дробь',
  'чакра',
  'шланг',
  'стенд',
  'кофта',
  'секта',
  'дятел',
  'якорь',
  'приют',
  'грива',
  'узник',
  'запор',
  'пушка',
  'кефир',
  'кожух',
  'линза',
  'взмах',
  'крупа',
  'лунка',
  'финка',
  'иврит',
  'каток',
  'банан',
  'гонка',
  'масть',
  'уклон',
  'мэрия',
  'рубец',
  'пятак',
  'вояка',
  'навал',
  'заход',
  'узбек',
  'шторм',
  'талон',
  'сачок',
  'глушь',
  'загар',
  'пенек',
  'досуг',
  'мамка',
  'песик',
  'слизь',
  'амбар',
  'конус',
  'парик',
  'абзац',
  'тягач',
  'самец',
  'флора',
  'плеть',
  'житье',
  'хмель',
  'ножны',
  'ширма',
  'чижик',
  'лилия',
  'уклад',
  'нарта',
  'лямка',
  'наган',
  'лицей',
  'тиран',
  'счеты',
  'руина',
  'вылет',
  'оклад',
  'шорты',
  'стужа',
  'яичко',
  'эвенк',
  'рожок',
  'исток',
  'опрос',
  'нотка',
  'умник',
  'вожжа',
  'засов',
  'калий',
  'обрез',
  'жесть',
  'прядь',
  'шпала',
  'бронх',
  'гладь',
  'бурка',
  'вальс',
  'вилка',
  'ртуть',
  'гамма',
  'показ',
  'обвал',
  'алмаз',
  'буран',
  'лужок',
  'кубик',
  'спрут',
  'кисет',
  'тумба',
  'вишня',
  'мания',
  'мушка',
  'выпад',
  'шмель',
  'осина',
  'комод',
  'манеж',
  'кудри',
  'биржа',
  'салют',
  'завет',
  'зебра',
  'жилет',
  'самка',
  'говор',
  'запой',
  'чужак',
  'спица',
  'рокот',
  'дудка',
  'камаз',
  'отлет',
  'фужер',
  'короб',
  'блоха',
  'шпора',
  'штрих',
  'тыква',
  'пупок',
  'зачет',
  'плата',
  'изгиб',
  'будни',
  'баржа',
  'байка',
  'отвар',
  'ордер',
  'брань',
  'курок',
  'ролик',
  'сенат',
  'днище',
  'пасха',
  'шпана',
  'келья',
  'окрик',
  'питон',
  'мячик',
  'тонус',
  'качка',
  'насос',
  'черед',
  'гетто',
  'бачок',
  'джинн',
  'щечка',
  'чурка',
  'судак',
  'пижон',
  'чулан',
  'часок',
  'сплав',
  'булка',
  'упырь',
  'шпиль',
  'шаман',
  'плеск',
  'газик',
  'желчь',
  'жилка',
  'эскиз',
  'зенит',
  'нажим',
  'актив',
  'кулек',
  'табун',
  'тезка',
  'сдвиг',
  'дебил',
  'загон',
  'порча',
  'слива',
  'десна',
  'лесть',
  'смерч',
  'тузик',
  'часик',
  'недуг',
  'юнкер',
  'копна',
  'рифма',
  'валет',
  'гонец',
  'отлив',
  'опека',
  'аршин',
  'астма',
  'бремя',
  'галоп',
  'шрифт',
  'жучок',
  'ничья',
  'завуч',
  'трест',
  'пешка',
  'биток',
  'бирюк',
  'чешуя',
  'мятеж',
  'бляха',
  'гумми',
  'дупло',
  'мытье',
  'мумия',
  'скоба',
  'этика',
  'взнос',
  'выдох',
  'уксус',
  'забой',
  'пивко',
  'кирка',
  'чугун',
  'шатер',
  'циник',
  'топка',
  'финиш',
  'обруч',
  'малек',
  'тучка',
  'рулон',
  'жетон',
  'тюбик',
  'гнида',
  'ходок',
  'кроха',
  'бойня',
  'шубка',
  'пудра',
  'чернь',
  'кладь',
  'отпор',
  'досье',
  'дозор',
  'лучик',
  'шлейф',
  'смета',
  'хиппи',
  'посев',
  'ореол',
  'немка',
  'нищий',
  'оазис',
  'слюни',
  'полог',
  'имидж',
  'хомут',
  'нахал',
  'томик',
  'ссуда',
  'иудей',
  'осада',
  'альфа',
  'чрево',
  'басня',
  'титан',
  'хобби',
  'табло',
  'индус',
  'дебри',
  'лапша',
  'видео',
  'ларец',
  'терем',
  'тариф',
  'макет',
  'краса',
  'плешь',
  'зелье',
  'вираж',
  'милок',
  'ляжка',
  'севок',
  'санки',
  'резон',
  'валик',
  'окунь',
  'древо',
  'рупор',
]
